interface ICycleTimeFormat {
  success: boolean
  data: number
  message: string | null
}

export const checkCycleTimeFormat = (
  cycleTime: string,
  type: string
): ICycleTimeFormat => {
  const data: ICycleTimeFormat = {
    success: false,
    data: 0,
    message: null
  }

  //1. Check if the string includes :
  const cycleTimeString = String(cycleTime)

  if (!cycleTimeString.includes(':')) {
    data.success = false
    data.message = `Missing ':' ${type} Should be in mm:ss format`
    return data
  }

  const arr = cycleTimeString.split(':')

  if (arr.length !== 2) {
    data.success = false
    data.message = ` ${type} Should be in mm:ss format`
    return data
  }

  if (Number(arr[0]) >= 60) {
    data.success = false
    data.message = `Minutes should be less than 60 in ${type}`
    return data
  }

  if (Number(arr[1]) >= 60) {
    data.success = false
    data.message = `Seconds should be less than 60 in ${type}`
    return data
  }

  return {
    ...data,
    success: true,
    data: Number(arr[0]) * 60 + Number(arr[1])
  }
}
