import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledPageHeader from '../../components/StyledPageHeader/StyledPageHeader'
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import { useDateSelectorContext } from '../../context/DateSelectorContext'

//utils
import { showNotification } from '../../utils/toast.utils'
import { MACHINES } from '../../utils/constant.utils'
import * as DATE_UTILS from '../../utils/date.utils'

//services
import liveDataService from '../../service/liveData.service'

// TODO: Enabled all machines update BE API to support machine grouping
// { label: 'All machines', value: 0 }
const machineOptions = [...MACHINES]

const ProductionRecord = () => {
  const [productionRecordRows, setProductionRecordRows] = useState([])
  const [machine, setMachine] = useState(machineOptions[0])
  const { selectedDate } = useDateSelectorContext()
  const [availableShifts, setAvailableShifts] = useState([])
  const [selectedShift, setSelectedShift] = useState({
    label: 'All shifts',
    value: 0
  })
  const [isLoading, setIsLoading] = useState(false)

  const fetchProductionRecords = async () => {
    const params = {}

    if (selectedDate) {
      params.date = selectedDate
    }

    if (machine.value !== 0) {
      params.machine = machine.value
    }

    try {
      setIsLoading(true)
      const productionRecordResponse =
        await liveDataService.getProductionRecords(params)
      console.log(productionRecordResponse)
      const data = productionRecordResponse.data.data
      const availableShifts = []
      const partsData = data.map((data, i) => {
        const shiftValue = `S${data.shift}`
        if (!availableShifts.includes(shiftValue)) {
          availableShifts.push(shiftValue)
        }
        return {
          index: i + 1,
          machine: data.machine,
          shift: shiftValue,
          partName: data.partName,
          partType: data.partType,
          workOrderNum: data.workOrderNum,
          quantity: data.quantity,
          productionId: data.productionId,
          palletCount: data.palletCount,
          cycleTime: data.cycleTime,
          startTime: data.time,
          partTarget: data.shiftTarget,
          timeTarget: data.timeTarget
        }
      })
      setAvailableShifts([
        {
          label: 'All shifts',
          value: 0
        },
        ...availableShifts.map((shift) => {
          return { label: shift, value: shift }
        })
      ])
      setProductionRecordRows(partsData)
    } catch (err) {
      console.log(err)
      setProductionRecordRows([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (machine === null) {
      return
    }
    fetchProductionRecords()
  }, [machine, selectedDate])

  // const clearAll = async () => {
  //   setSelectedDate(DATE_UTILS.todaysDate())
  //   setMachine(machineOptions[0])
  //   await fetchProductionRecords()
  // }

  //1. Create search object
  const searchObj = {
    // ...(machine &&
    //   (machine.value !== 'all' || machine.value === 0) && {
    //     machine: machine.value
    //   }),
    ...(selectedShift &&
      selectedShift.value &&
      selectedShift.value !== 0 && {
        shift: selectedShift.value
      })
  }

  //2. Extract the keys into an array
  const keysToChecked = Object.keys(searchObj)
  console.log('keysToChecked', keysToChecked, searchObj)

  //3. filter
  let filteredRows = productionRecordRows
  if (keysToChecked.length > 0) {
    filteredRows = productionRecordRows.filter((productionRecordRow) => {
      return keysToChecked.every(
        (key) => searchObj[key] === productionRecordRow[key]
      )
    })
  }

  const productionRecordColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'Machine',
      accessor: 'machine'
    },
    {
      Header: 'Shift',
      accessor: 'shift'
    },
    {
      Header: 'Part number',
      accessor: 'partName'
    },
    {
      Header: 'Operation',
      accessor: 'partType'
    },
    {
      Header: 'Work order',
      accessor: 'workOrderNum'
    },
    // {
    //   Header: 'Cycle time(sec)',
    //   accessor: 'cycleTime',
    // },
    {
      Header: 'WO quantity',
      accessor: 'quantity'
    },
    {
      Header: 'Production ID',
      accessor: 'productionId'
    },
    {
      Header: 'Start time',
      accessor: 'startTime'
    },
    {
      Header: 'Part target',
      accessor: 'partTarget'
    },

    {
      Header: 'Time target(min)',
      accessor: 'timeTarget'
    }
  ]

  return (
    <>
       <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '24px',
          padding: '16px',
          backgroundColor: '#fff',
          borderBottom: '1px solid #eeeeee'
        }}
      >
          <StyledSelectField
            label=""
            placeHolderText="Select shift"
            selectedValue={selectedShift}
            selectOptions={availableShifts}
            onChangeHandler={(value) => setSelectedShift(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No shift found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select machine"
            selectedValue={machine}
            selectOptions={machineOptions}
            onChangeHandler={(value) => setMachine(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No machine found"
          />
          {/* <Button
            //startIcon={<ClearIcon />}
            variant="contained"
            sx={{
              color: '#fff'
            }}
            onClick={clearAll}
          >
            Clear
          </Button> */}
        </Stack>
        <Stack
        sx={{
          gap: '16px',
          padding: '16px'
        }}
      >
      <StyledTableContainer>
        {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
        {!isLoading && filteredRows.length === 0 && (
          <StyledEmptyHeader>There are no production record</StyledEmptyHeader>
        )}
        {!isLoading && filteredRows.length > 0 && (
          <StyledTable
            pagination={false}
            columns={productionRecordColumns}
            data={filteredRows}
            hiddenColumns={['machine']}
          />
        )}
      </StyledTableContainer>
      </Stack>
    </>
  )
}

export default ProductionRecord
