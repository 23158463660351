import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { styled } from '@mui/material'
import Divider from '@mui/material/Divider'

//components
import MenuItem from './MenuItem'

//assets
import Logo from '../assets/logo.png'
import { SIDE_PANEL_WIDTH } from '../utils/constants'

import HomeIcon from '@mui/icons-material/Home'
import BackupTableIcon from '@mui/icons-material/BackupTable'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import HandymanIcon from '@mui/icons-material/Handyman'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import BarChartIcon from '@mui/icons-material/BarChart'

const App = styled('main')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  color: '#424242',
  backgroundColor: '#fff'
}))

const SidePanel = styled('div')(({ theme }) => ({
  width: `${SIDE_PANEL_WIDTH}px`,
  height: '100vh',
  borderRight: '1px solid #dedede'
}))

const SidePanelInnerDiv = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px'
}))

const MainContent = styled('div')(({ theme }) => ({
  width: `calc(100% - ${SIDE_PANEL_WIDTH}px)`,
  width: '100%',
  height: '100vh',
  overflowY: 'auto'
}))

const LogoWrapper = styled('img')(({ theme }) => ({
  maxWidth: '100%'
}))

const LogoBox = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 16px',
  marginTop: 'auto'
}))

const NavBox = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  marginTop: '12px'
}))

const MENU_ICON_FONT = 'small'
const SidebarData = [
  {
    title: 'Home',
    path: '/',
    renderIcon: () => {
      return <HomeIcon fontSize={MENU_ICON_FONT} />
    }
  },
  {
    title: 'Dashboard',
    path: '/dashboard',
    renderIcon: () => {
      return <BackupTableIcon fontSize={MENU_ICON_FONT} />
    }
  },
  {
    title: 'Live data',
    path: '/dashboard/live-data',
    renderIcon: () => {
      return <RssFeedIcon fontSize={MENU_ICON_FONT} />
    }
  },
  {
    title: 'Machine wise',
    path: '/dashboard/machine-wise',
    renderIcon: () => {
      return <PrecisionManufacturingIcon fontSize={MENU_ICON_FONT} />
    }
  },
  {
    title: 'Tool life',
    path: '/dashboard/tool-life',
    renderIcon: () => {
      return <HandymanIcon fontSize={MENU_ICON_FONT} />
    }
  }
]

const historicalData = [
  {
    title: 'Historical',
    path: '/dashboard/historical',
    renderIcon: () => {
      return <BarChartIcon fontSize="medium" />
    }
  }
]

const DashboardLayout = () => {
  return (
    <App>
      <SidePanel>
        <SidePanelInnerDiv>
          <NavBox>
            {SidebarData.map((item, index) => (
              <MenuItem key={index} item={item} />
            ))}
          </NavBox>
          <Divider
            sx={{
              margin: '4px 0',
              backgroundColor: 'gray'
            }}
          />
          <NavBox>
            {historicalData.map((item, index) => (
              <MenuItem key={index} item={item} />
            ))}
          </NavBox>
          <LogoBox>
            <Link to="/">
              <LogoWrapper src={Logo} alt="company logo" />
            </Link>
          </LogoBox>
        </SidePanelInnerDiv>
      </SidePanel>
      <MainContent>
        <Outlet />
      </MainContent>
    </App>
  )
}

export default DashboardLayout
