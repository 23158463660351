import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import Stack from '@mui/material/Stack'

//components
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import RadialGauge from '../../components/RadialGauge/RadialGauge'
import IdletimeWrapperMachineWise from './IdletimeWrapperMachineWise'
import RejectionWrapperMachineWise from './RejectionWrapperMachineWise'
import { useDateSelectorContext } from '../../context/DateSelectorContext'

//utils
import { MACHINES } from '../../utils/constant.utils'
import { showNotification } from '../../utils/toast.utils'
import * as dashboardService from '../../service/dashboard.service'

const OEEWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  gap: '24px',
  flex: 1
}))

const KPITable = styled('table')(() => ({
  borderCollapse: 'collapse',
  width: '100%'
}))

const KPITableRow = styled('tr')(() => ({
  width: '100%'
  // '&:nth-of-type(even)': {
  //   backgroundColor: '#dddddd'
  // }
}))

const KPITableEntry = styled('td')(() => ({
  border: '1px solid #dddddd',
  textAlign: 'left',
  padding: '8px'
}))

const OEECard = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  padding: '16px',
  border: '1px solid #ddd',
  borderRadius: '12px'
}))

const KPIItem = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  flex: 1
}))

const machineOptions = [
  { label: 'All machines', value: 'overall' },
  ...MACHINES
]

const MachineWise = () => {
  //context
  const { selectedDate } = useDateSelectorContext()
  //local
  const [availableShifts, setAvailableShifts] = useState([])
  const [machineWiseData, setMachineWiseData] = useState({})
  const [selectedMachine, setSelectedMachine] = useState(machineOptions[0])
  const [selectedShift, setSelectedShift] = useState({
    label: 'All shifts',
    value: 'day'
  })
  const [isLoading, setIsLoading] = useState(false)

  const fetchData = async () => {
    const params = {}

    if (selectedDate) {
      params.date = selectedDate
    }
    try {
      setIsLoading(true)

      const listOfPromises = [
        dashboardService.getDailyReportMachineWiseV2(params)
      ]

      const response = await Promise.allSettled(listOfPromises)

      if (response[0].status === 'fulfilled') {
        const liveData = response[0].value.data.data.productionInfo
        const availableShifts = response[0].value.data.data.availableShifts

        const filteredLiveData = {
          ...liveData,
          variantsArray: liveData.variantsArray.filter(
            (item) => item.partCount !== 0
          )
        }

        setMachineWiseData(filteredLiveData)
        setAvailableShifts([
          {
            label: 'All shifts',
            value: 'day'
          },
          ...availableShifts.map((shift) => {
            return { label: shift, value: shift }
          })
        ])
      } else {
        showNotification(response[0].reason.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [selectedDate])

  let formattedData = []
  let OEEData = []
  if (machineWiseData) {
    if (machineWiseData.variantsArray) {
      formattedData = machineWiseData.variantsArray
    }

    if (machineWiseData[selectedMachine.value]) {
      OEEData = Object.values(machineWiseData[selectedMachine.value])
    }
  }

  //1. Create search object
  const searchObj = {
    ...(selectedMachine &&
      selectedMachine.value &&
      selectedMachine.value !== 'overall' && {
        machine: selectedMachine.value
      }),
    ...(selectedShift &&
      selectedShift.value &&
      selectedShift.value !== 'day' && {
        shift: selectedShift.value
      })
  }
  //changed everything to 'day' for rendering upon page load
  //2. Extract the keys into an array
  const keysToCheck = Object.keys(searchObj)

  //3. filter
  if (keysToCheck.length > 0) {
    formattedData = formattedData.filter((productionData) => {
      return keysToCheck.every((key) => searchObj[key] === productionData[key])
    })
  }

  if (searchObj['shift']) {
    OEEData = OEEData.filter((oeeData) => {
      return searchObj['shift'] === oeeData['shift']
    })
  }

  const columns = [
    {
      Header: 'Machine',
      accessor: 'machine'
    },
    {
      Header: 'Shift',
      accessor: 'shift'
    },
    {
      Header: 'Part number',
      accessor: 'partName'
    },
    {
      Header: 'Operation',
      accessor: 'partType'
    },
    {
      Header: 'Work order',
      accessor: 'workOrderNum'
    },
    {
      Header: 'WO quantity',
      accessor: 'quantity'
    },
    {
      Header: 'Production ID',
      accessor: 'productionId'
    },
    {
      Header: 'Part count',
      accessor: 'partCount'
    },
    {
      Header: 'Target',
      accessor: 'target'
    },
    {
      Header: 'Rejection',
      accessor: 'rejectionCount'
    }
  ]

  const KPIList = (shift) => [
    {
      name: 'Availability',
      value: shift.availability
    },
    {
      name: 'Quality',
      value: shift.quality
    },
    {
      name: 'Performance',
      value: shift.performance
    }
    // {
    //   name: 'OEE',
    //   value: shift.OEE
    // }
  ]

  const parameters = (shift) => [
    {
      name: 'Achieved quantity',
      value: shift.partCount
    },
    {
      name: 'Shift target',
      value: shift.shiftTarget
    },
    {
      name: 'Rejected quantity',
      value: shift.rejectionCount
    },
    {
      name: 'Total time',
      value: shift.totalTime
    },
    {
      name: 'Idletime',
      value: shift.idleTime
    }
  ]

  return (
    <>
       <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '24px',
          padding: '16px',
          backgroundColor: '#fff',
          borderBottom: '1px solid #eeeeee',
          zIndex: 20
        }}
      >
          <StyledSelectField
            label=""
            placeHolderText="Select shift"
            selectedValue={selectedShift}
            selectOptions={availableShifts}
            onChangeHandler={(value) => setSelectedShift(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No shift found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select machine"
            selectedValue={selectedMachine}
            selectOptions={machineOptions}
            onChangeHandler={(value) => setSelectedMachine(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No machine found"
          />
        </Stack>
      <Stack
        sx={{
          width: '100%',
          gap: '16px',
          padding: '16px'
        }}
      >
        <OEEWrapper>
          {selectedMachine.value !== 0 &&
            OEEData &&
            OEEData.length > 0 &&
            OEEData.map((shift, index) => (
              <OEECard key={index}>
                <KPIItem>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flex: 1
                    }}
                  >
                    <h3>{shift.shift}</h3>
                    <RadialGauge value={shift.OEE} />
                  </div>
                </KPIItem>
                <KPIItem>
                  <KPITable>
                    {parameters(shift).map((item, index) => (
                      <KPITableRow key={index}>
                        <KPITableEntry>{item.name}</KPITableEntry>
                        <KPITableEntry>{item.value}</KPITableEntry>
                      </KPITableRow>
                    ))}
                  </KPITable>
                </KPIItem>
                <KPIItem>
                  {KPIList(shift).map((item) => (
                    <div
                      key={index}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <h4>
                        {item.name}{' '}
                        <span style={{ fontWeight: 'normal' }}>
                          ({Number(item.value).toFixed(2) + '%'})
                        </span>{' '}
                      </h4>
                      <div
                        style={{
                          backgroundColor: 'rgb(192, 192, 192)',
                          width: '100%',
                          height: '10px',
                          borderRadius: '5px'
                        }}
                      >
                        <div
                          style={{
                            width:
                              `${
                                item.value > 100
                                  ? 100
                                  : Math.round(Number(item.value)).toFixed(2)
                              }` + '%',
                              height: '100%',
                              borderRadius: '4px',
                              backgroundColor: '#62a5e8'
                          }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </KPIItem>
              </OEECard>
            ))}
        </OEEWrapper>
        <StyledTableContainer>
          {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
          {!isLoading && formattedData.length === 0 && (
            <StyledEmptyHeader>
              There are no parts available, try selecting different date.
            </StyledEmptyHeader>
          )}
          {!isLoading && formattedData.length > 0 && (
            <StyledTable
              pagination={false}
              columns={columns}
              data={formattedData}
              hiddenColumns={[]}
            />
          )}
        </StyledTableContainer>
        <IdletimeWrapperMachineWise
          selectedDate={selectedDate}
          selectedMachine={selectedMachine}
          selectedShift={selectedShift}
        />
        <RejectionWrapperMachineWise
          selectedDate={selectedDate}
          selectedMachine={selectedMachine}
          selectedShift={selectedShift}
        />
      </Stack>
    </>
  )
}

export default MachineWise
