import React from 'react'
import { useNavigate } from 'react-router-dom'

//components
import RippleEffect from '../../../components/RippleEffect/RippleEffect'
import StyledTable from '../../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../../components/StyledTableContainer/StyledTableContainer'

import { getDisplayName } from '../../../utils/common.utils'
import { MACHINES } from '../../../utils/constant.utils'

const LiveDataTabular = ({ machineData }) => {
  const navigate = useNavigate()

  const openMachinePage = (rowValues) => {
    navigate(
      `/dashboard/machine-wise?tab=overview&machine=${rowValues.machine}`
    )
  }

  const machineColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'Status',
      accessor: 'machineStatus',
      Cell: ({ cell: { row } }) => {
        return (
          <RippleEffect
            machineStatus={
              row.values.machineStatus === true ||
              row.values.machineStatus === 1
                ? false
                : true
            }
          />
        )
      }
    },
    {
      Header: 'Machine',
      accessor: 'machine',
      Cell: ({ cell: { row } }) => {
        return getDisplayName(row.values.machine, MACHINES)
      }
    },
    {
      Header: 'Operator',
      accessor: 'operator'
    },
    {
      Header: 'Part number',
      accessor: 'name'
    },
    {
      Header: 'Operation',
      accessor: 'type'
    },
    {
      Header: 'Part count',
      accessor: 'partCount'
    },
    {
      Header: 'Rejection count',
      accessor: 'rejectionCount'
    },
    {
      Header: 'Idletime',
      accessor: 'idleTime'
    }
  ]

  return (
    <StyledTableContainer>
      <StyledTable
        pagination={false}
        rowClickHandler={openMachinePage}
        columns={machineColumns}
        data={machineData}
        hiddenColumns={[]}
      />
    </StyledTableContainer>
  )
}

export default LiveDataTabular
