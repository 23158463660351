import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
//import AddIcon from '@mui/icons-material/Add'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'
import AddLoadUnloadTimeModal from './AddLoadUnloadModal'

//assets
import { DeleteIcon, EditIcon } from '../../icons/Icons'

//utils
import { showNotification } from '../../utils/toast.utils'

//services
import loadUnloadTimeService from '../../service/loadUnloadTime.service'
import { checkCycleTimeFormat } from '../../utils/validateCycleTime'

const FilterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-between',
  alignItems: 'center',
  columnGap: '16px'
}))

const LoadUnloadTime = () => {
  const [loadUnloadTime, setLoadUnloadTime] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [openAddModal, setOpenAddModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  console.log(selectedItem)

  const fetchLoadUnloadTime = async () => {
    try {
      setIsLoading(true)
      const response = await loadUnloadTimeService.getLoadUnloadTimes()

      if (response.success) {
        setLoadUnloadTime(
          response.data.map((item, index) => ({
            index: index + 1,
            ...item
          }))
        )
      }
    } catch (err) {
      console.log(err)
      setLoadUnloadTime([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchLoadUnloadTime()
  }, [])

  const addLoadUnloadTime = async (loadUnloadTime) => {
    if (!loadUnloadTime) {
      showNotification('loadUnload Time is required', 'error')
      return
    }

    const loadUnloadTimeValidation = checkCycleTimeFormat(
      loadUnloadTime,
      'Load and unload time'
    )

    if (!loadUnloadTimeValidation.success) {
      showNotification(loadUnloadTimeValidation.message, 'error')
      return
    }

    const data = {
      loadUnloadTime: loadUnloadTime
    }

    try {
      setIsLoading(true)
      const response = await loadUnloadTimeService.addLoadUnloadTime(data)
      if (response.data.success) {
        showNotification(`load unload Time added successfully`, 'success')
        setOpenAddModal(false)
      } else {
        showNotification(response.data.message, 'error')
      }

      fetchLoadUnloadTime()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const editLoadUnloadTime = async (id, loadUnloadTimeInfo) => {
    if (!loadUnloadTimeInfo) {
      showNotification('load unload Time is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const response = await loadUnloadTimeService.editLoadUnloadTime(id, {
        loadUnloadTime: loadUnloadTimeInfo
      })
      if (response.status === 200) {
        showNotification(`load unload Time updated successfully`, 'success')
      } else {
        showNotification(response.data.message, 'error')
      }
      setOpenAddModal(false)
      fetchLoadUnloadTime()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
      setIsEditMode(false)
    }
  }

  const deleteLoadUnloadTime = async (loadUnloadTimeId) => {
    if (!loadUnloadTimeId) {
      showNotification('loadUnload Time id is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const response =
        await loadUnloadTimeService.deleteLoadUnloadTime(loadUnloadTimeId)
      if (response.data.success) {
        showNotification(`loadUnload Time deleted successfully`, 'success')
      } else {
        showNotification(response.data.message, 'error')
      }
      fetchLoadUnloadTime()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const loadUnloadTimeColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Load unload time',
      accessor: 'loadUnloadTime'
    },
    // {
    //   Header: "Created by",
    //   accessor: "createdBy",
    // },
    {
      Header: 'Actions',
      Cell: ({ cell: { row } }) => {
        return (
          <div
            style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}
          >
            <EditIcon
              onClickHandler={() => {
                setIsEditMode(true)
                setSelectedItem({ ...row.values })
                setOpenAddModal(true)
              }}
            />
            <DeleteIcon
              onClickHandler={() => {
                setSelectedItem({ ...row.values })
                setOpenDeleteModal(true)
              }}
            />
          </div>
        )
      }
    }
  ]

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'column',
          gap: '12px',
          borderBottom: '1px solid #eeeeee',
          backgroundColor: '#fff',
          padding: '12px 16px',
          zIndex: 100
        }}
      >
        <FilterContainer>
          <Button
            //startIcon={<AddIcon />}
            variant="contained"
            sx={{
              textTransform: 'none',
              color: '#fff'
            }}
            onClick={() => {
              setIsEditMode(false)
              setSelectedItem({})
              setOpenAddModal(true)
            }}
          >
            Add load unload Time
          </Button>
        </FilterContainer>
      </Stack>
      <Box sx={{ padding: '16px' }}>
        <StyledTableContainer>
          {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
          {!isLoading && loadUnloadTime.length === 0 && (
            <StyledEmptyHeader>There are no load unload Time</StyledEmptyHeader>
          )}
          {!isLoading && loadUnloadTime.length > 0 && (
            <StyledTable
              pagination={false}
              columns={loadUnloadTimeColumns}
              data={loadUnloadTime}
              hiddenColumns={['id']}
            />
          )}
        </StyledTableContainer>
      </Box>
      {openAddModal && (
        <AddLoadUnloadTimeModal
          closeHandler={() => setOpenAddModal(false)}
          isModalOpen={openAddModal}
          addLoadUnloadTime={addLoadUnloadTime}
          editLoadUnloadTime={editLoadUnloadTime}
          isEditMode={isEditMode}
          selectedPart={selectedItem}
        />
      )}
      {openDeleteModal && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`Do you want to delete the following operation type ${selectedItem.type}`}
          buttonTitle="Delete"
          clickHandler={() => deleteLoadUnloadTime(selectedItem.id)}
          closeHandler={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  )
}

export default LoadUnloadTime
