import React from 'react'
import { styled } from '@mui/material'

const PageContainer = styled('div')(() => ({
  height: '100%',
  width: '100%'
}))

const StyledPageContainer = (props) => {
  const { children } = props

  return <PageContainer>{children}</PageContainer>
}

export default StyledPageContainer
