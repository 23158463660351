import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'

import DashBoardAnalytics from './components/DashBoardAnalytics/DashBoardAnalytics'
import ProductionTrendOverall from './components/ProductionTrend/ProductionTrendOverAll'
import ProductionTrendMachineWise from './components/ProductionTrend/ProductionTrendMachineWise'
import RejectionTrend from './components/RejectionTrend'
import RejectionDoughnut from './components/RejectionDoughnut'
import OeeTrend from './components/OeeTrend/OeeTrend'
import IdleTimeWrapper from './IdleTimeWrapper'

import { showNotification } from '../../utils/toast.utils'
import liveDataService from '../../service/liveData.service'
import { useMonthSelectorContext } from '../../context/MonthSelectorContext'

const MyMachineDashboard = () => {
  const { selectedDate } = useMonthSelectorContext()
  const [isLoading, setIsLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)

        const response = await liveDataService.getDashboardData({
          currentDate: selectedDate
        })

        if (response.status === 200) {
          setDashboardData(response.data.data)
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [selectedDate])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '16px'
        }}
      >
        {isLoading && <>Loading...</>}
        {!isLoading && (
          <>
            {dashboardData['overAll'] && (
              <DashBoardAnalytics data={dashboardData['overAll']} />
            )}
            {dashboardData['productionTrend'] && (
              <ProductionTrendOverall
                productionTrend={dashboardData['productionTrend']}
              />
            )}
            {dashboardData['machineWiseProductionInfo'] && (
              <ProductionTrendMachineWise
                machineWiseProductionInfo={
                  dashboardData['machineWiseProductionInfo']
                }
              />
            )}
            {dashboardData['oeeTrend'] && (
              <OeeTrend oeeTrendData={dashboardData['oeeTrend']} />
            )}
            <IdleTimeWrapper selectedDate={selectedDate} />
            {dashboardData['rejectionTrend'] && (
              <RejectionTrend
                rejectionTrendData={dashboardData['rejectionTrend']}
              />
            )}
            {dashboardData['rejectionDataReasonWise'] &&
              dashboardData['rejectionReasonMap'] && (
                <RejectionDoughnut
                  rejectionReasonData={dashboardData['rejectionDataReasonWise']}
                  rejectionReasonMap={dashboardData['rejectionReasonMap']}
                />
              )}
          </>
        )}
      </Box>
    </>
  )
}

export default MyMachineDashboard
