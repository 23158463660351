import React from 'react'

import BarChart from '../../../components/Charts/BarChart'

import * as CHART_UTILS from '../../../components/Charts/BarChart.constant'

const Idletime = ({ isLoading, groupedData }) => {
  const chartTitle = 'Hourly part produced'

  const chartLabels = []
  const data = []

  if (groupedData) {
    for (const [hour, hourlyData] of Object.entries(groupedData)) {
      let totalQuantity = 0

      if (!Array.isArray(hourlyData)) {
        chartLabels.push(hour)
        data.push(totalQuantity)
        continue
      }

      hourlyData.forEach((data) => {
        totalQuantity += data.idleTime
      })

      chartLabels.push(hour)
      data.push(totalQuantity)
    }
  }

  const dataGroup = [
    {
      ...CHART_UTILS.BAR,
      label: 'Idletime',
      data: data,
      backgroundColor: '#7afade',
      borderColor: '#04b890'
    }
    // {
    //   ...CHART_UTILS.BAR,
    //   label: "Shift-2",
    //   data: dasboardData["oeeTrend"].map((el) => el["s2_oee"]),
    //   backgroundColor: "#f5ae67",
    //   borderColor: "#b55d05",
    // },
  ]

  if (isLoading) {
    return <div>Loading....</div>
  }

  return (
    <div
      style={{
        padding: '16px 0'
      }}
    >
      <BarChart
        chartTitle={chartTitle}
        chartLabels={chartLabels}
        dataGroup={dataGroup}
        xLabel="Hours"
        yLabel="Idletime"
        minValue={0}
        maxValue={60}
        stepValue={5}
        height="30%"
      />
    </div>
  )
}

export default Idletime
