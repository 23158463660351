import React from 'react'
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledPageHeader from '../../components/StyledPageHeader/StyledPageHeader'

const ToolLife = () => {
  return (
    <StyledPageContainer>
      <StyledPageHeader title="Tool life management" />
      coming soon...
    </StyledPageContainer>
  )
}

export default ToolLife
