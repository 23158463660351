import React, { useState, useEffect } from 'react'
import { Typography, styled } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import TableRowsIcon from '@mui/icons-material/TableRows'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import Box from '@mui/material/Box'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import LiveDataGrid from './components/LiveDataGrid'
import LiveDataTabular from './components/LiveDataTabular'

import liveDataService from '../../service/liveData.service'
import { capitalizeFirstLetter } from '../../utils/string.utils'
import { showNotification } from '../../utils/toast.utils'

const TabsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '12px',
  padding: '8px 24px'
}))

const Tab = styled('div')(({ theme, isActive }) => ({
  minWidth: 'fit-content',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 0px',
  color: isActive ? theme.palette.primary.main : '#ccccd7',
  cursor: 'pointer',
  '&:hover': {
    color: `${theme.palette.primary.main}`
  }
}))

const LAYOUT_SELECTION_TABS = {
  table: { icon: <TableRowsIcon />, value: 'table' },
  grid: { icon: <ViewModuleIcon />, value: 'grid' }
}

const MachineLiveData = () => {
  let defaultTab = LAYOUT_SELECTION_TABS.table.value

  //hooks
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const layout = params.get('layout')

  if (layout) {
    defaultTab = layout
  }

  //local
  const [activeSubTab, setActiveSubTab] = useState(defaultTab)
  const [liveStatusData, setLiveStatusData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchMachineStatus = async () => {
      try {
        setIsLoading(true)

        const response = await liveDataService.getLiveStatus()

        if (response.data.success) {
          const machineWiseData = Object.values(response.data.data)
          const data = machineWiseData.map((machine, i) => {
            return {
              index: i + 1,
              machine: machine.machine,
              name: machine.partName,
              type: machine.partType,
              partCount: machine.partCount,
              rejectionCount: machine.rejectionCount,
              machineStatus: machine.machineStatus,
              operator: machine.operator,
              idleTime: machine.idleTime
            }
          })
          setLiveStatusData(data)
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchMachineStatus()
    const timeId = setInterval(() => {
      try {
        fetchMachineStatus()
      } catch (error) {
        console.log(error)
      }
    }, 30 * 1000)

    return () => {
      clearInterval(timeId)
    }
  }, [])

  useEffect(() => {
    navigate(`?layout=${activeSubTab}`)
  }, [activeSubTab])

  const handleSubTabChange = (tab) => {
    setActiveSubTab(tab)
  }

  return (
    <StyledPageContainer>
      <TabsContainer>
        {React.Children.toArray(
          Object.keys(LAYOUT_SELECTION_TABS).map((key, i) => (
            <Tab
              isActive={activeSubTab === LAYOUT_SELECTION_TABS[key].value}
              onClick={() =>
                handleSubTabChange(LAYOUT_SELECTION_TABS[key].value)
              }
            >
              {LAYOUT_SELECTION_TABS[key].icon}
            </Tab>
          ))
        )}
      </TabsContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 56px)',
          padding: '0px 16px',
          overflowY: 'auto'
        }}
      >
      <div>
        {isLoading && <Typography>Updating live status...</Typography>}
        {!isLoading && liveStatusData.length === 0 && (
          <StyledEmptyHeader>
            Live data not available try after some time
          </StyledEmptyHeader>
        )}
        {!isLoading && liveStatusData && liveStatusData.length > 0 && (
          <>
            {activeSubTab === LAYOUT_SELECTION_TABS.table.value && (
              <LiveDataTabular machineData={liveStatusData} />
            )}
            {activeSubTab === LAYOUT_SELECTION_TABS.grid.value && (
              <LiveDataGrid machineData={liveStatusData} />
            )}
          </>
        )}
      </div>
      </Box>
    </StyledPageContainer>
  )
}

export default MachineLiveData
