const monthsArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const maxDate = () => {
  let d = new Date()
  //d.setDate(d.getDate() - 1)
  return d.toISOString().slice(0, 10)
}

export const todaysDate = () => {
  let d = new Date()
  return d.toISOString().slice(0, 10)
}

export const workingDate = () => {
  let d = new Date()
  // d.setDate(d.getDate() - 1)
  return d.toISOString().slice(0, 10)
}

const isFutureDate = (currentDate) => {
  const todaysDate = new Date()
  // todaysDate.setDate(todaysDate.getDate() - 1)
  if (currentDate >= todaysDate) {
    return true
  }
  return false
}

export const previousDate = (currentDate) => {
  let d = new Date(currentDate)
  d.setDate(d.getDate() - 1)
  if (!isFutureDate(d)) {
    return d.toISOString().slice(0, 10)
  }
  return currentDate
}

export const nextDate = (currentDate) => {
  let d = new Date(currentDate)
  d.setDate(d.getDate() + 1)
  if (!isFutureDate(d)) {
    return d.toISOString().slice(0, 10)
  }
  return currentDate
}

export const updateDate = (actionType, prevDate) => {
  return actionType === 'prev' ? previousDate(prevDate) : nextDate(prevDate)
}

export const getMonthAndYear = (queryDate) => {
  let d = new Date(queryDate)
  return { sMonth: monthsArray[d.getMonth()], sYear: d.getFullYear() }
}

export const dayGetter = (dateString) => {
  const dd = dateString.slice(0, 2)
  const mm = dateString.slice(3, 5)
  const yyyy = dateString.slice(6)
  //console.log(`${yyyy}-${mm}-${dd}`);
  const reqDate = new Date(`${yyyy}-${mm}-${dd}`)
  //console.log(reqDate);
  const d = new Date(reqDate.getFullYear(), reqDate.getMonth() + 1, 0)
  return {
    daysInMonth: d.getDate(),
    monthOfReport: monthsArray[reqDate.getMonth()]
  }
}

export const currentMonthAndYear = () => {
  let d = new Date()

  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}`
}

const getPreviousAndNextMonth = (inputMonth) => {
  // Parse the input month string to create a Date object
  const currentDate = new Date(inputMonth);

  // Get the current month and year
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Calculate the previous month
  const previousMonth = new Date(currentYear, currentMonth - 1, 1);
  // Adjust for cases where the current month is January
  if (currentMonth === 0) {
    previousMonth.setFullYear(currentYear - 1);
    previousMonth.setMonth(11); // December
  }

  // Calculate the next month
  const nextMonth = new Date(currentYear, currentMonth + 1, 1);
  // Adjust for cases where the current month is December
  if (currentMonth === 11) {
    nextMonth.setFullYear(currentYear + 1);
    nextMonth.setMonth(0); // January
  }

  // Format the results as strings (e.g., "2023-12" for December 2023)
  const previousMonthString = `${previousMonth.getFullYear()}-${(previousMonth.getMonth() + 1).toString().padStart(2, '0')}`;
  const nextMonthString = `${nextMonth.getFullYear()}-${(nextMonth.getMonth() + 1).toString().padStart(2, '0')}`;

  return { previousMonth: previousMonthString, nextMonth: nextMonthString };
}

export const updateMonthAndYear = (actionType, inputMonth) => {
  const {previousMonth, nextMonth} = getPreviousAndNextMonth(inputMonth)
  return actionType === 'prev' ? previousMonth : nextMonth
}

// Example usage:
// const inputMonth = "2023-06";
// const result = getPreviousAndNextMonth(inputMonth);
// console.log("Previous Month:", result.previousMonth);
// console.log("Next Month:", result.nextMonth);
