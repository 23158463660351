import React, { useEffect, useState } from 'react'
import IdleTimeDoughnut from '../../components/IdleTimeDoughnut/ChartWithoutControls'

import { showNotification } from '../../utils/toast.utils'
import * as dashboardService from '../../service/dashboard.service'

const IdletimeWrapperMachineWise = ({
  selectedDate,
  selectedMachine,
  selectedShift
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [idletimeData, setIdletimeData] = useState({})
  const [idleReasonMap, setIdleReasonMap] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const params = {}

      if (selectedDate) {
        params.date = selectedDate
      }
      try {
        setIsLoading(true)

        const listOfPromises = [dashboardService.getIdletimeDataForDay(params)]

        const response = await Promise.allSettled(listOfPromises)

        if (response[0].status === 'fulfilled') {
          setIdletimeData(response[0].value.data.data.idleTimeMachineWiseData)
          setIdleReasonMap(response[0].value.data.data.idleReasonMap)
        } else {
          showNotification(response[0].reason.message, 'error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [selectedDate])

  if (isLoading) {
    return <>Loading..</>
  }

  return (
    <>
      {idleReasonMap &&
        idletimeData[selectedMachine.value] &&
        idletimeData[selectedMachine.value][selectedShift.value] && (
          <IdleTimeDoughnut
            idleTimeData={
              idletimeData[selectedMachine.value][selectedShift.value]
            }
            idleReasonMap={idleReasonMap}
          />
        )}
    </>
  )
}

export default IdletimeWrapperMachineWise
