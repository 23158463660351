import React from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Stack'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import PartUploadModal from './AddPartModal'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'

//assets
import { DeleteIcon, EditIcon } from '../../icons/Icons'

//utils
import { showNotification } from '../../utils/toast.utils'
import { MACHINES } from '../../utils/constant.utils'
import { checkCycleTimeFormat } from '../../utils/validateCycleTime'

//services
import partService from '../../service/part.service'

//types
import { OptionItem } from '../../types/common'
import { TypeAddPartData, PartsData } from '../../types/parts'

const FilterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-between',
  alignItems: 'center',
  columnGap: '16px'
}))

const machineOptions = MACHINES.map((machine) => ({
  value: machine.value,
  label: machine.label
}))

const ActionBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
      {children}
    </div>
  )
}

const PartManagement = () => {
  const defaultMachine = {
    value: 'all',
    label: 'All machines'
  }

  const defaultIsActiveFlag = {
    value: 'all',
    label: 'All parts'
  }

  const defaultPartName = {
    value: 'all',
    label: 'All part numbers'
  }

  const defaultOperation = {
    value: 'all',
    label: 'All operations'
  }

  const [parts, setParts] = React.useState<Record<number, PartsData> | null>(
    null
  )
  const [partNameOptions, setPartNameOptions] = React.useState<OptionItem[]>([])
  const [partTypeOptions, setPartTypeOptions] = React.useState<OptionItem[]>([])

  const [isActiveFlag, setIsActiveFlag] =
    React.useState<OptionItem>(defaultIsActiveFlag)
  const [partName, setPartName] = React.useState<OptionItem>(defaultPartName)
  const [partType, setPartType] = React.useState<OptionItem>(defaultOperation)
  const [machine, setMachine] = React.useState<OptionItem>(defaultMachine)

  const [isLoading, setIsLoading] = React.useState(false)
  const [isUpdatingPartList, setIsUpdatingPartList] = React.useState(false)
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [openAddPartModal, setOpenAddPartModal] = React.useState(false)
  const [selectedPart, setSelectedPart] = React.useState<PartsData | null>(null)
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)

  const fetchParts = async () => {
    const data = {}

    // if (partName.value !== 0) {
    //   data.partName = partName.value
    // }

    // if (partType.value !== 0) {
    //   data.partType = partType.value
    // }

    // if (machine.value !== 0) {
    //   data.machine = machine.value
    // }

    setIsLoading(true)
    const partsResponse = await partService.getParts(data)

    if (partsResponse.success) {
      const partsData: Record<number, PartsData> = {}
      const partNameOptionsObj: Record<string, OptionItem> = {}
      const partTypeOptionsObj: Record<string, OptionItem> = {}

      partsResponse.data.forEach((part, i) => {
        if (!partNameOptionsObj[part.name]) {
          partNameOptionsObj[part.name] = {
            label: part.name,
            value: part.name
          }
        }

        if (!partTypeOptionsObj[part.type]) {
          partTypeOptionsObj[part.type] = {
            label: part.type,
            value: part.type
          }
        }

        if (!partsData[part.id]) {
          partsData[part.id] = {
            index: i + 1,
            isSelected: false,
            ...part
          }
        }
      })

      setPartNameOptions(Object.values(partNameOptionsObj))
      setPartTypeOptions(Object.values(partTypeOptionsObj))
      setParts(partsData)
    } else {
      //TODO: set error or show error
      setParts(null)
    }

    setIsLoading(false)
  }

  React.useEffect(() => {
    fetchParts()
  }, [])

  const addPart = async (partData: TypeAddPartData) => {
    if (!partData.name) {
      showNotification('Part name is required', 'error')
      return
    }

    if (!partData.type) {
      showNotification('Part type is required', 'error')
      return
    }

    if (!partData.machine && partData.machine === '') {
      showNotification('Machine is required', 'error')
      return
    }

    const machineTimeValidation = checkCycleTimeFormat(
      partData.machiningTime,
      'Machining time'
    )

    if (!machineTimeValidation.success) {
      showNotification(machineTimeValidation.message, 'error')
      return
    }

    const loadUnloadTimeValidation = checkCycleTimeFormat(
      partData.loadUnloadTime,
      'Load and unload time'
    )

    if (!loadUnloadTimeValidation.success) {
      showNotification(loadUnloadTimeValidation.message, 'error')
      return
    }

    setIsLoading(true)
    const partsResponse = await partService.addPart(partData)
    if (partsResponse.success) {
      showNotification(`Part added successfully`, 'success')
    } else {
      showNotification(partsResponse.message, 'error')
    }
    setOpenAddPartModal(false)
    fetchParts()
    setIsLoading(false)
  }

  const editPart = async (id: number, partData: TypeAddPartData) => {
    if (!partData.name) {
      showNotification('Part name is required', 'error')
      return
    }

    if (!partData.type) {
      showNotification('Part type is required', 'error')
      return
    }

    if (!partData.machine && partData.machine === '') {
      showNotification('Machine is required', 'error')
      return
    }

    const machineTimeValidation = checkCycleTimeFormat(
      partData.machiningTime,
      'Machining time'
    )

    if (!machineTimeValidation.success) {
      showNotification(machineTimeValidation.message, 'error')
      return
    }

    const loadUnloadTimeValidation = checkCycleTimeFormat(
      partData.loadUnloadTime,
      'Load and unload time'
    )

    if (!loadUnloadTimeValidation.success) {
      showNotification(loadUnloadTimeValidation.message, 'error')
      return
    }

    setIsLoading(true)
    const partsResponse = await partService.editPart(id, partData)
    if (partsResponse.success) {
      showNotification(`Part updated successfully`, 'success')
    } else {
      showNotification(partsResponse.message, 'error')
    }

    fetchParts()
    setIsEditMode(false)
    setOpenAddPartModal(false)
    setIsLoading(false)
  }

  const deletePart = async (partId: number) => {
    if (!partId) {
      showNotification('part id is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const partResponse = await partService.deletePart(partId)
      if (partResponse.data.success) {
        showNotification(`Part deleted successfully`, 'success')
      } else {
        showNotification(partResponse.data.message, 'error')
      }
      fetchParts()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelection = (partId: number, value: boolean) => {
    setParts((prev) => ({
      ...prev,
      ...(prev &&
        prev[partId] && {
          [partId]: {
            ...prev[partId],
            isSelected: value
          }
        })
    }))
  }

  const updateProductionList = async (isActiveFlag: boolean) => {
    if (!parts) return

    const partIdsToBeUpdated = Object.values(parts)
      .filter((part) => part.isSelected)
      .map((part) => part.id)

    if (partIdsToBeUpdated.length === 0) {
      showNotification('Select parts to continue', 'error')
      return
    }

    //console.log({ partIdsToBeUpdated, isActiveFlag })

    setIsUpdatingPartList(true)
    const response = await partService.updateProductionList(
      partIdsToBeUpdated,
      isActiveFlag
    )
    if (response.success) {
      showNotification(`Production list updated successfully`, 'success')
    } else {
      showNotification(response.message, 'error')
    }
    setIsUpdatingPartList(false)
    fetchParts()
  }

  const clearAll = () => {
    setIsActiveFlag(defaultIsActiveFlag)
    setPartName(defaultPartName)
    setPartType(defaultOperation)
    setMachine(defaultMachine)
  }

  //1. Create search object
  const searchObj = {
    ...(isActiveFlag &&
      isActiveFlag.value !== 'all' && {
        isActive: isActiveFlag.value
      }),
    ...(machine &&
      machine.value !== 'all' && {
        machine: machine.value
      }),
    ...(partName &&
      partName.value !== 'all' && {
        name: partName.value
      }),
    ...(partType &&
      partType.value !== 'all' && {
        type: partType.value
      })
  }
  console.log({ searchObj })

  //2. Extract the keys into an array
  const keysToChecked = Object.keys(searchObj) as (keyof typeof searchObj)[]

  //3. filter
  const filteredRows = React.useMemo(() => {
    let filteredRows = parts ? Object.values(parts) : []
    if (parts && keysToChecked.length > 0) {
      filteredRows = Object.values(parts).filter((partRow) => {
        return keysToChecked.every((key) => searchObj[key] === partRow[key])
      })
    }

    return filteredRows
  }, [parts, machine, partName, partType, isActiveFlag])

  console.log({ filteredRows })

  const partColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'Select',
      accessor: 'isSelected',
      Cell: ({ cell: { row } }: { cell: { row: { values: PartsData } } }) => {
        return (
          <Checkbox
            disabled={!searchObj.machine}
            sx={{
              padding: 0
            }}
            checked={row.values.isSelected}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleSelection(row.values.id, event.target.checked)
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )
      }
    },
    {
      Header: 'Label',
      accessor: 'isActive',
      Cell: ({ cell: { row } }: { cell: { row: { values: PartsData } } }) => {
        return (
          <Box
            sx={{
              fontSize: '14px',
              color: row.values.isActive ? 'green' : '#000',
              backgroundColor: row.values.isActive ? '#f0fff7' : 'gray',
              borderRadius: '4px'
            }}
          >
            {row.values.isActive ? 'Active' : 'Inactive'}
          </Box>
        )
      }
    },
    {
      Header: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Part number',
      accessor: 'name'
    },
    {
      Header: 'Operation',
      accessor: 'type'
    },
    {
      Header: 'Machine',
      accessor: 'machine'
    },
    {
      Header: 'Machining time (mm:ss)',
      accessor: 'machiningTime'
    },
    {
      Header: 'Load & unload time (mm:ss)',
      accessor: 'loadUnloadTime'
    },
    {
      Header: 'Action',
      Cell: ({ cell: { row } }: { cell: { row: { values: PartsData } } }) => {
        return (
          <ActionBox>
            <EditIcon
              onClickHandler={() => {
                setIsEditMode(true)
                setSelectedPart({ ...row.values })
                setOpenAddPartModal(true)
              }}
            />
            <DeleteIcon
              onClickHandler={() => {
                setOpenDeleteModal(true)
                setSelectedPart({ ...row.values })
              }}
            />
          </ActionBox>
        )
      }
    }
  ]

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'column',
          gap: '12px',
          borderBottom: '1px solid #eeeeee',
          backgroundColor: '#fff',
          padding: '12px 16px',
          zIndex: 100
        }}
      >
      <FilterContainer>
        <Button
          variant="contained"
          sx={{
            color: '#fff',
            textTransform: 'none'
          }}
          onClick={() => {
            setIsEditMode(false)
            setSelectedPart(null)
            setOpenAddPartModal(true)
          }}
        >
          Add new part
        </Button>
        <Button
          variant="outlined"
          sx={{
            textTransform: 'none',
            color: '#000'
          }}
          onClick={() => updateProductionList(true)}
          disabled={isUpdatingPartList || machine.value === 'all'}
        >
          Add to production list
        </Button>
        <Button
          variant="outlined"
          sx={{
            textTransform: 'none',
            color: '#000'
          }}
          onClick={() => updateProductionList(false)}
          disabled={isUpdatingPartList || machine.value === 'all'}
        >
          Remove from production list
        </Button>
        <Button
          startIcon={<FilterAltOffIcon />}
          variant="outlined"
          sx={{
            marginLeft: 'auto', 
            textTransform: 'none',
            color: '#000'
          }}
          onClick={clearAll}
          disabled={keysToChecked.length === 0}
        >
          Clear
        </Button>
      </FilterContainer>
      <Stack
        spacing={2}
        direction="row"
        sx={{ width: '100%', alignItems: 'center' }}
      >
        <StyledSelectField
          label=""
          placeHolderText="Select Active or Inactive"
          selectedValue={isActiveFlag}
          selectOptions={[
            defaultIsActiveFlag,
            { label: 'Active', value: 1 },
            { label: 'Inactive', value: 0 }
          ]}
          onChangeHandler={(value: OptionItem) => setIsActiveFlag(value)}
          isMulti={false}
          isSearchable={false}
          isDisabled={false}
        />
        <StyledSelectField
          label=""
          placeHolderText="Select part number"
          selectedValue={partName}
          selectOptions={[defaultPartName, ...partNameOptions]}
          onChangeHandler={(value: OptionItem) => setPartName(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No part number found"
        />
        <StyledSelectField
          label=""
          placeHolderText="Select operation type"
          selectedValue={partType}
          selectOptions={[defaultOperation, ...partTypeOptions]}
          onChangeHandler={(value: OptionItem) => setPartType(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No operation type"
        />
        <StyledSelectField
          label=""
          placeHolderText="Select machine"
          selectedValue={machine}
          selectOptions={[defaultMachine, ...machineOptions]}
          onChangeHandler={(value: OptionItem) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No machine found"
        />
      </Stack>
      </Stack>
      <Box sx={{ padding: '16px' }}>
      <StyledTableContainer>
        {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
        {!isLoading && filteredRows.length === 0 && (
          <StyledEmptyHeader>There are no parts</StyledEmptyHeader>
        )}
        {!isLoading && filteredRows.length > 0 && (
          <StyledTable
            pagination={false}
            columns={partColumns}
            data={filteredRows}
            hiddenColumns={['id', 'index']}
          />
        )}
      </StyledTableContainer>
      </Box>
      {openAddPartModal && (
        <PartUploadModal
          closeHandler={() => setOpenAddPartModal(false)}
          isModalOpen={openAddPartModal}
          addPart={addPart}
          editPart={editPart}
          isEditMode={isEditMode}
          selectedPart={selectedPart}
        />
      )}
      {selectedPart && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`Do you want to delete the following variant ${selectedPart.name}_${selectedPart.type}_${selectedPart.machine}`}
          buttonTitle="Delete"
          clickHandler={() => deletePart(selectedPart.id)}
          closeHandler={() => setOpenDeleteModal(false)}
          />
      )}
    </>
  )
  }

export default PartManagement
