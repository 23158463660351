import React, { useState, useEffect } from 'react'
import { default as ReactSelect } from 'react-select'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import StyledSelectField from '../../../../components/StyledSelectField/StyledSelectField'

import * as DATE_UTILS from '../../../../utils/date.utils'

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '8px',
  padding: '10px 0'
}))

const ActionBtnContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
}))

const DateForm = styled('form')(() => ({
  display: 'flex',
  gap: '8px'
}))

const DateInput = styled('input')(() => ({
  outline: 'none',
  borderRadius: '8px',
  padding: '4px 8px'
}))

const InputHeader = ({
  date,
  header,
  dateChangeHandler,
  submitHandler,
  prevNextHandler,
  machineList,
  currentMachine,
  machineChangeHandler,

  machineData,

  operatorList,
  currentOperator,
  operatorChangeHandler,
  isLoading
}) => {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%'
    })
    // control: (provided) => ({
    //   ...provided,
    //   height: "20px",
    // }),
  }

  return (
    <Container>
      <DateForm>
        <DateInput
          id="queryDate"
          type="date"
          value={date}
          min="2021-01-15"
          max={DATE_UTILS.maxDate()}
          onChange={dateChangeHandler}
        />
        <Button disabled={isLoading} variant="outlined" onClick={submitHandler}>
          Submit
        </Button>
      </DateForm>
      <div>
        {machineList && (
          <select
            id="machine"
            value={currentMachine.toUpperCase()}
            onChange={machineChangeHandler}
          >
            {machineList.map((machine) => (
              <option key={machine} value={machine}>
                {machine}
              </option>
            ))}
          </select>
        )}
        {operatorList && (
          <ReactSelect
            styles={customStyles}
            placeholder="Select Operator"
            options={operatorList}
            isSearchable
            noOptionsMessage={() => 'No Operator Found'}
            onChange={(value) => operatorChangeHandler(value)}
          />
        )}
      </div>
      <ActionBtnContainer>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={() => prevNextHandler('prev')}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={() => prevNextHandler('next')}
        >
          <ArrowForwardIcon />
        </Button>
      </ActionBtnContainer>
      <div style={{ marginLeft: 'auto', display: 'flex', width:'300px' }}>
        {machineData && (
          <StyledSelectField
            label=""
            placeHolderText="Select machine"
            selectedValue={machineData.selectedMachine}
            selectOptions={machineData.machineOptions}
            onChangeHandler={(value) => machineData.setSelectedMachine(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No machine found"
          />
        )}
      </div>
    </Container>
  )
}

export default InputHeader
