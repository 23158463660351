import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'

import StyledModal from '../../components/StyledModal/Modal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

import { MACHINES } from '../../utils/constant.utils'
import { PartsData, TypeAddPartData } from '../../types/parts'
import { OptionItem } from '../../types/common'

interface Props {
  isModalOpen: boolean
  closeHandler: () => void
  selectedPart: PartsData | null
  addPart?: (partData: TypeAddPartData) => void
  editPart?: (id: number, newData: TypeAddPartData) => void
  isEditMode?: boolean
  isLoading?: boolean
}

const PartUploadModal = (props: Props) => {
  let defaultPartName = ''
  let defaultPartType: OptionItem = {
    value: 0,
    label: 'Select operation type'
  }
  let defaultMachine: OptionItem = {
    value: 0,
    label: 'Select machine'
  }
  let defaultLoadUnloadTime: OptionItem = {
    value: 0,
    label: 'Select Load unload time'
  }
  let defaultMachiningTime = ''
  let defaultIsActive = true

  if (props.isEditMode && props.selectedPart) {
    defaultPartName = props.selectedPart.name

    defaultPartType.value = props.selectedPart.type
    defaultPartType.label = props.selectedPart.type

    defaultMachine.value = props.selectedPart.machine
    defaultMachine.label = props.selectedPart.machine.toUpperCase()

    defaultMachiningTime = props.selectedPart.machiningTime

    defaultLoadUnloadTime.value = props.selectedPart.loadUnloadTime
    defaultLoadUnloadTime.label = props.selectedPart.loadUnloadTime

    defaultIsActive = props.selectedPart.isActive
  }

  const [partTypeOptions, setPartTypeOptions] = useState([])
  const [loadUnloadTimeOptions, setLoadUnloadTimeOptions] = useState([])
  const [partName, setPartName] = useState(defaultPartName)
  const [partType, setPartType] = useState(defaultPartType)
  const [machine, setMachine] = useState(defaultMachine)
  const [machiningTime, setMachiningTime] = useState(defaultMachiningTime)
  const [loadUnloadTime, setLoadUnloadTime] = useState(defaultLoadUnloadTime)
  const [isActive, setIsActive] = useState(defaultIsActive)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ADMIN_BASE_URL}/v1/operation-type`)
      .then((response) => response.json())
      .then((data) => {
        const partTypeOptions = data.data.map((item: any) => ({
          value: item.type,
          label: item.type
        }))
        setPartTypeOptions(partTypeOptions)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ADMIN_BASE_URL}/v1/load-unload-time`)
      .then((response) => response.json())
      .then((data) => {
        const loadUnloadTimeOptions = data.data.map((item: any) => ({
          value: item.loadUnloadTime,
          label: item.loadUnloadTime
        }))
        setLoadUnloadTimeOptions(loadUnloadTimeOptions)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <StyledModal
      width={500}
      loading={props.isLoading}
      isOpen={props.isModalOpen}
      title={props.isEditMode ? 'Edit part' : 'Add part'}
      closeHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledTextFieldWithLabel
          label="Part number"
          placeholderText="Enter part number"
          textValue={partName}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPartName(e.target.value)
          }
          isDisabled={false}
          type="text"
          isMargin={true}
        />
        <StyledSelectField
          label="Operation type"
          placeHolderText="Select operation type"
          selectedValue={partType}
          selectOptions={partTypeOptions}
          onChangeHandler={(value: OptionItem) => setPartType(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No Operation type found"
        />
        <StyledSelectField
          label="Machine"
          placeHolderText="Select machine"
          selectedValue={machine}
          selectOptions={MACHINES}
          onChangeHandler={(value: OptionItem) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No machine found"
        />
        <StyledTextFieldWithLabel
          label="Machining time (mm:ss)"
          placeholderText="Enter machining time. Eg: 01:50"
          textValue={machiningTime}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            setMachiningTime(e.target.value)
          }
          isDisabled={false}
          onBlurHandler
          type="text"
        />
        <StyledSelectField
          label="Load and unload time (mm:ss)"
          placeHolderText="Select load and unload time"
          textValue={loadUnloadTime}
          selectedValue={loadUnloadTime}
          selectOptions={loadUnloadTimeOptions}
          onChangeHandler={(value: OptionItem) => setLoadUnloadTime(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No load and unload time found"
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ color: '#000' }}>Add to production list</Typography>
          <Checkbox
            sx={{ padding: 0 }}
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
        </Box>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ color: '#fff' }}
          onClick={() =>
            props.isEditMode && props.selectedPart
              ? props.editPart &&
                props.editPart(props.selectedPart.id, {
                  name: partName,
                  type: partType.value,
                  machine: machine.value,
                  machiningTime,
                  loadUnloadTime: loadUnloadTime.value,
                  isActive
                })
              : props.addPart &&
                props.addPart({
                  name: partName,
                  type: partType.value,
                  machine: machine.value,
                  machiningTime,
                  loadUnloadTime: loadUnloadTime.value,
                  isActive: true
                })
          }
        >
          {props.isEditMode ? 'Update' : 'Add part'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default PartUploadModal
