import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import StyledModal from '../../components/StyledModal/StyledModal'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

const AddLoadUnloadTimeModal = (props) => {
  let defaultLoadTime = '00:00'

  if (props.isEditMode) {
    defaultLoadTime = props.selectedPart.loadUnloadTime
  }

  const [loadTime, setLoadTime] = useState(defaultLoadTime)

  return (
    <StyledModal
      modalWidth={500}
      loaderState={props.isLoading}
      isModalOpen={props.isModalOpen}
      modalTitle={props.isEditMode ? 'Edit load time ' : 'Add load time'}
      modalCloseHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        {/* <StyledTextFieldWithLabel
          label=""
          placeholderText="Enter load and unload time"
          textValue={loadTime}
          onChangeHandler={(e) => setLoadTime(e.target.value)}
          isDisabled={false}
          loadUnloadTime="text"
          isMargin={true}
        /> */}
        <StyledTextFieldWithLabel
          label="Load and unload time (mm:ss)"
          placeholderText="Enter load and unload time. Eg: 01:50"
          textValue={loadTime}
          onChangeHandler={(e) => setLoadTime(e.target.value)}
          isDisabled={false}
          type="text"
        />
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            color: '#fff'
          }}
          onClick={() =>
            props.isEditMode
              ? props.editLoadUnloadTime(props.selectedPart.id, loadTime)
              : props.addLoadUnloadTime(loadTime)
          }
        >
          {props.isEditMode ? 'Update' : 'Add'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default AddLoadUnloadTimeModal
