import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledPageHeader from '../../components/StyledPageHeader/StyledPageHeader'
import CreateShiftConfig from './components/CreateShiftConfig'
import ConfigList from './components/ConfigList'

const liveDataTabs = [
  { name: 'Configuration list', value: 'config-list' },
  { name: 'Create configuration', value: 'create-config' }
]

const TabsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: '24px',
  //backgroundColor: theme.palette.background.paper,
  padding: '8px 24px 0 0',
  marginBottom: '16px'
  //marginTop: '24px',
}))

const Tab = styled('div')(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  minWidth: 'fit-content',
  padding: '8px 0px',
  color: isActive ? theme.palette.primary.main : '#ccccd7',
  cursor: 'pointer',
  borderBottom: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : '1px solid transparent',
  '&:hover': {
    color: `${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`
  }
}))

const CreateConfig = () => {
  let defaultTab = liveDataTabs[0].value

  //hooks
  const navigate = useNavigate()
  const location = useLocation()

  //extract category from URL
  const params = new URLSearchParams(location.search)
  const tab = params.get('tab')

  //If category present in the URL override the defaultJobCategory
  if (tab) {
    defaultTab = tab
  }

  //component state
  const [activeSubTab, setActiveSubTab] = useState(defaultTab)

  useEffect(() => {
    navigate(`?tab=${activeSubTab}`)
  }, [activeSubTab])

  const handleSubTabChange = (tab) => {
    setActiveSubTab(tab)
  }

  return (
    <StyledPageContainer>
      <TabsContainer>
        {React.Children.toArray(
          liveDataTabs.map((tab, i) => (
            <Tab
              isActive={activeSubTab === tab.value}
              onClick={() => handleSubTabChange(tab.value)}
            >
              {tab.name}
            </Tab>
          ))
        )}
      </TabsContainer>
      {activeSubTab === 'config-list' && <ConfigList />}
      {activeSubTab === 'create-config' && <CreateShiftConfig />}
    </StyledPageContainer>
  )
}

export default CreateConfig
