import React, { useState, useEffect, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box' // Fixed import
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

import StyledTable from '../../components/ReactTable/StyledTable'
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'
import EmployeeUploadModal from './AddEmployeeModal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'

//assets
import { DeleteIcon, EditIcon } from '../../icons/Icons'

//services
import employeeService from '../../service/employee.service'

//Utils
import { showNotification } from '../../utils/toast.utils'
import { ROLES } from '../../utils/constant.utils'

//types
import { OptionItem } from '../../types/common'
import { EmployeeAddList, EmployeeData, IEmployee } from '../../types/employee'

const FilterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  columnGap: '16px'
}))

const ActionBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
      {children}
    </div>
  )
}

const EmployeeManagement = () => {
  const defaultIsActiveFlag = { value: 'all', label: 'All status' }
  const defaultId = { value: 'all', label: 'All employee IDs' }
  const defaultName = { value: 'all', label: 'All names' }
  const defaultRole = { value: 'all', label: 'All roles' }

  const [employees, setEmployees] = useState<Record<
    string,
    EmployeeData
  > | null>(null)
  const [employeeIdOptions, setEmployeeIdOptions] = useState<OptionItem[]>([])
  const [employeeNameOptions, setEmployeeNameOptions] = useState<OptionItem[]>(
    []
  )
  const [roleOptions, setRoleOptions] = useState(
    ROLES.map((role) => ({ value: role.value, label: role.label }))
  )

  const [isActiveFlag, setIsActiveFlag] =
    useState<OptionItem>(defaultIsActiveFlag)
  const [role, setRole] = useState<OptionItem>(defaultRole)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeData | null>(
    null
  )
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isUpdatingEmployeeList, setIsUpdatingEmployeeList] = useState(false)

  const [name, setName] = useState<OptionItem>(defaultName)
  const [id, setId] = useState<OptionItem>(defaultId)

  const fetchEmployees = async () => {
    const data = {}

    setIsLoading(true)
    const employeesResponse = await employeeService.getEmployees(data)

    if (employeesResponse.success) {
      const employeesData: Record<string, EmployeeData> = {}
      const employeeIdOptionsObj: Record<string, OptionItem> = {}
      const employeeNameOptionsObj: Record<string, OptionItem> = {}

      employeesResponse.data.forEach((employee, i) => {
        if (!employeeIdOptionsObj[employee.id]) {
          employeeIdOptionsObj[employee.id] = {
            label: employee.id,
            value: employee.id
          }
        }

        if (!employeeNameOptionsObj[employee.name]) {
          employeeNameOptionsObj[employee.name] = {
            label: employee.name,
            value: employee.name
          }
        }

        if (!employeesData[employee.id]) {
          employeesData[employee.id] = {
            index: i + 1,
            isSelected: false,
            ...employee
          }
        }
      })

      setEmployees(employeesData)
      setEmployeeIdOptions(Object.values(employeeIdOptionsObj))
      setEmployeeNameOptions(Object.values(employeeNameOptionsObj))
    } else {
      setEmployees(null)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchEmployees()
  }, [])

  const addEmployee = async (employeeData: EmployeeAddList) => {
    if (!employeeData.id) {
      showNotification('Employee ID is required', 'error')
      return
    }
    if (!employeeData.name) {
      showNotification('Employee name is required', 'error')
      return
    }

    if (!employeeData.role) {
      showNotification('Role is required', 'error')
      return
    }

    setIsLoading(true)
    const employeeResponse = await employeeService.addEmployee(employeeData)

    if (employeeResponse.success) {
      showNotification('Employee added successfully', 'success')
    } else {
      showNotification(employeeResponse.message, 'error')
    }
    setOpenAddEmployeeModal(false)
    fetchEmployees()
    setIsLoading(false)
  }

  const editEmployee = async (id: string, employeeData: EmployeeAddList) => {
    if (!employeeData.name) {
      showNotification('Employee name is required', 'error')
      return
    }

    if (!employeeData.role) {
      showNotification('Employee role is required', 'error')
      return
    }
    // if (!employeeData.id && employeeData.id === '') {
    //   showNotification('Employee id is required', 'error')
    //   return
    // }

    setIsLoading(true)
    const employeeResponse = await employeeService.editEmployee(
      id,
      employeeData
    )
    if (employeeResponse.success) {
      showNotification('Employee updated successfully', 'success')
    } else {
      showNotification(employeeResponse.message, 'error')
    }
    fetchEmployees()
    setIsEditMode(false)
    setOpenAddEmployeeModal(false)
    setIsLoading(false)
  }

  const deleteEmployee = async (employeeId: string) => {
    if (!employeeId) {
      showNotification('Employee id is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const employeeResponse = await employeeService.deleteEmployee(employeeId)
      console.log(employeeResponse)
      if (employeeResponse.success) {
        showNotification('Employee deleted successfully', 'success')
      } else {
        showNotification(employeeResponse.data.message, 'error')
      }
      fetchEmployees()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelection = (employeeId: string, value: boolean) => {
    setEmployees((prev) => ({
      ...prev,
      ...(prev &&
        prev[employeeId] && {
          [employeeId]: {
            ...prev[employeeId],
            isSelected: value
          }
        })
    }))
  }

  const updateEmployeeList = async (isActiveFlag: boolean) => {
    if (!employees) return

    const employeeIdsToBeUpdated = Object.values(employees)
      .filter((employee) => employee.isSelected)
      .map((employee) => employee.id)

    // if (employeeIdsToBeUpdated.some(isNaN)) {
    //   console.error(
    //     'One or more employee IDs are not valid numbers:',
    //     employeeIdsToBeUpdated
    //   )
    //   showNotification('Invalid employee ID(s)', 'error')
    //   return
    // }

    if (employeeIdsToBeUpdated.length === 0) {
      showNotification('Select employee to continue', 'error')
      return
    }

    setIsUpdatingEmployeeList(true)
    const response = await employeeService.updateEmployeeList(
      employeeIdsToBeUpdated,
      isActiveFlag
    )
    if (response.success) {
      showNotification(`Employee list updated successfully`, 'success')
    } else {
      showNotification(response.message, 'error')
    }
    setIsUpdatingEmployeeList(false)
    fetchEmployees()
  }

  const clearAll = () => {
    setIsActiveFlag(defaultIsActiveFlag)
    setId(defaultId)
    setName(defaultName)
    setRole(defaultRole)
  }

  const searchObj = {
    ...(isActiveFlag &&
      isActiveFlag.value !== 'all' && {
        isActive: isActiveFlag.value
      }),
    ...(id &&
      id.value !== 'all' && {
        id: id.value
      }),
    ...(role &&
      role.value !== 'all' && {
        role: role.value
      }),

    ...(name &&
      name.value !== 'all' && {
        name: name.value
      })
  }

  const keysToChecked = Object.keys(searchObj) as (keyof typeof searchObj)[]

  const filteredRows = React.useMemo(() => {
    let filteredRows = employees ? Object.values(employees) : []
    if (employees && keysToChecked.length > 0) {
      filteredRows = Object.values(employees).filter((employeeRow) => {
        return keysToChecked.every((key) => searchObj[key] === employeeRow[key])
      })
    }

    return filteredRows
  }, [employees, role, id, name, isActiveFlag])

  console.log({ filteredRows })

  const employeeColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'Select',
      accessor: 'isSelected',
      Cell: ({
        cell: { row }
      }: {
        cell: { row: { values: EmployeeData } }
      }) => {
        return (
          <Checkbox
            disabled={!searchObj.role}
            sx={{
              padding: 0
            }}
            checked={row.values.isSelected}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleSelection(row.values.id, event.target.checked)
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )
      }
    },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Employee ID', accessor: 'id' },
    { Header: 'Designation', accessor: 'role' },
    {
      Header: 'Label',
      accessor: 'isActive',
      Cell: ({
        cell: { row }
      }: {
        cell: { row: { values: EmployeeData } }
      }) => {
        return (
          <Box
            sx={{
              fontSize: '14px',
              color: row.values.isActive ? 'green' : '#000',
              backgroundColor: row.values.isActive ? '#f0fff7' : 'gray',
              borderRadius: '4px'
            }}
          >
            {row.values.isActive ? 'Active' : 'Inactive'}
          </Box>
        )
      }
    },

    { Header: 'Created by', accessor: 'createdBy' },
    {
      Header: 'Action',
      Cell: ({
        cell: { row }
      }: {
        cell: { row: { values: EmployeeData } }
      }) => {
        return (
          <ActionBox>
            <EditIcon
              onClickHandler={() => {
                setIsEditMode(true)
                setSelectedEmployee({ ...row.values })
                setOpenAddEmployeeModal(true)
              }}
            />
            <DeleteIcon
              onClickHandler={() => {
                setOpenDeleteModal(true)
                setSelectedEmployee({ ...row.values })
              }}
            />
          </ActionBox>
        )
      }
    }
  ]

  return (
    <>
    <Stack
      sx={{
        position: 'sticky',
        top: 0,
        width: '100%',
        flexDirection: 'column',
        gap: '12px',
        borderBottom: '1px solid #eeeeee',
        backgroundColor: '#fff',
        padding: '12px 16px',
        zIndex: 100
      }}
    >
      <FilterContainer>
        <Button
          variant="contained"
          sx={{
            color: '#fff',
            textTransform: 'none'
          }}
          onClick={() => {
            setIsEditMode(false)
            setSelectedEmployee(null)
            setOpenAddEmployeeModal(true)
          }}
        >
          Add employee
        </Button>
        <Button
          variant="outlined"
          sx={{
            textTransform: 'none',
            color: '#000'
          }}
          onClick={() => updateEmployeeList(true)}
          disabled={isUpdatingEmployeeList || role.value === 'all'}
        >
          Add to employee list
        </Button>
        <Button
          variant="outlined"
          sx={{
            textTransform: 'none',
            color: '#000'
          }}
          onClick={() => updateEmployeeList(false)}
          disabled={isUpdatingEmployeeList || role.value === 'all'}
        >
          Remove from employee list
        </Button>
        <Button
          startIcon={<FilterAltOffIcon />}
          variant="outlined"
          sx={{
            marginLeft: 'auto',
            textTransform: 'none',
            color: '#000'
          }}
          onClick={clearAll}
          disabled={keysToChecked.length === 0}
        >
          Clear
        </Button>
      </FilterContainer>
      <Stack
        spacing={2}
        direction="row"
        sx={{ width: '100%', alignItems: 'center' }}
      >
        <StyledSelectField
          label=""
          placeHolderText="Select Active or Inactive"
          selectedValue={isActiveFlag}
          selectOptions={[
            defaultIsActiveFlag,
            { label: 'Active', value: '1' },
            { label: 'Inactive', value: '0' }
          ]}
          onChangeHandler={(value: OptionItem) => setIsActiveFlag(value)}
          isMulti={false}
          isSearchable={false}
          isDisabled={false}
        />
        <StyledSelectField
          label=""
          placeHolderText="Select Employee Name"
          selectedValue={name}
          selectOptions={[defaultName, ...employeeNameOptions]}
          onChangeHandler={(value: OptionItem) => setName(value)}
          isMulti={false}
          isSearchable={true}
        />
        <StyledSelectField
          label=""
          placeHolderText="Select Role"
          selectedValue={role}
          selectOptions={[defaultRole, ...roleOptions]}
          onChangeHandler={(value: OptionItem) => setRole(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
        />
      </Stack>
      </Stack>
      <Box sx={{ padding: '16px' }}>
      <StyledTableContainer>
        {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
        {!isLoading && filteredRows.length === 0 && (
          <StyledEmptyHeader>No employees found</StyledEmptyHeader>
        )}
        {!isLoading && filteredRows.length > 0 && (
          <StyledTable
            pagination={false}
            columns={employeeColumns}
            data={filteredRows}
            hiddenColumns={['index', 'createdBy']}
          />
        )}
      </StyledTableContainer>
      </Box>
      {openAddEmployeeModal && (
        <EmployeeUploadModal
          closeHandler={() => setOpenAddEmployeeModal(false)}
          isModalOpen={openAddEmployeeModal}
          addEmployee={addEmployee}
          editEmployee={editEmployee}
          isEditMode={isEditMode}
          selectedEmployee={selectedEmployee}
        />
      )}

      {selectedEmployee && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`Do you want to delete the following employee ${selectedEmployee.id}_${selectedEmployee.name}`}
          buttonTitle="Delete"
          clickHandler={() => deleteEmployee(selectedEmployee.id)}
          closeHandler={() => setOpenDeleteModal(false)}
          />
        )}
      </>
    )
  }

export default EmployeeManagement
