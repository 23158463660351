import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import StyledModal from '../../components/StyledModal/StyledModal'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

const AddOperationTypeModal = (props) => {
  let defaultPartType = ''

  if (props.isEditMode) {
    defaultPartType = props.selectedPart.type
  }

  const [partType, setPartType] = useState(defaultPartType)

  return (
    <StyledModal
      modalWidth={500}
      loaderState={props.isLoading}
      isModalOpen={props.isModalOpen}
      modalTitle={
        props.isEditMode ? 'Edit operation type ' : 'Add operation type'
      }
      modalCloseHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledTextFieldWithLabel
          label=""
          placeholderText="Enter operation type"
          textValue={partType}
          onChangeHandler={(e) => setPartType(e.target.value)}
          isDisabled={false}
          type="text"
          isMargin={true}
        />
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            color: '#fff'
          }}
          onClick={() =>
            props.isEditMode
              ? props.editPart(props.selectedPart.id, partType)
              : props.addPart(partType)
          }
        >
          {props.isEditMode ? 'Update' : 'Add'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default AddOperationTypeModal
