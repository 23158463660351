import React, { useState, useEffect } from 'react'

import IdletimeTrend from './components/IdleTimeTrend/IdleTimeTrend'
import IdleTimeDoughnut from './components/IdleTimeDoughnut'

import { showNotification } from '../../utils/toast.utils'
import liveDataService from '../../service/liveData.service'
import * as DATE_UTILS from '../../utils/date.utils'

import { useMonthSelectorContext } from '../../context/MonthSelectorContext'

const IdleTimeWrapper = () => {
  const { selectedDate } = useMonthSelectorContext()
  const [isLoading, setIsLoading] = useState(false)
  const [workingDate, setWorkingDate] = useState(DATE_UTILS.workingDate())
  const [calenderDate, setCalenderDate] = useState(DATE_UTILS.workingDate())
  const [idleTimeData, setIdleTimeData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)

        const response = await liveDataService.getIdleTimeData({
          currentDate: selectedDate
        })

        if (response.status === 200) {
          setIdleTimeData(response.data.data)
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [selectedDate])

  if (isLoading) {
    return <>Loading..</>
  }
  return (
    <>
      {idleTimeData['idleTimeTrend'] && (
        <IdletimeTrend idleTimeTrendData={idleTimeData['idleTimeTrend']} />
      )}
      {idleTimeData['idleTimeReasonData'] && idleTimeData['idleReasonMap'] && (
        <IdleTimeDoughnut
          idleTimeData={idleTimeData['idleTimeReasonData']}
          idleReasonMap={idleTimeData['idleReasonMap']}
        />
      )}
    </>
  )
}

export default IdleTimeWrapper
