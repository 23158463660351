import axios from 'axios'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const loadUnloadTimeService = {
  getLoadUnloadTimes: async (data) => {
    try {
      const response = await axiosObj.get('/load-unload-time')

      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  addLoadUnloadTime: async (data) => {
    try {
      const response = await axiosObj.post('/load-unload-time', data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  editLoadUnloadTime: async (id, data) => {
    try {
      const response = await axiosObj.patch(`/load-unload-time/${id}`, data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  deleteLoadUnloadTime: async (id, data) => {
    try {
      const response = await axiosObj.delete(`/load-unload-time/${id}`, data)
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default loadUnloadTimeService
