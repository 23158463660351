import axios from 'axios'
import { IEmployee, EmployeeAddList, NameFilter } from '../types/employee'
import { ApiErrorResponse, ApiSuccessResponse } from '../types/common'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

const handleApiError = (error: unknown): ApiErrorResponse => {
  console.log({ error })

  if (axios.isAxiosError(error)) {
    //Handle specific HTTP status codes if needed

    //NOTE: This syntax has some parsing problem
    // if (error.response?.status === 404) {
    //   console.log('Not Found Error:', error.message)
    // }

    if (error.response && error.response.status === 404) {
      console.log('Not Found Error:', error.message)
    }
    if (error.response && error.response.status === 401) {
      console.log('Auth error:', error.message)
    }
    return { success: false, message: 'Internal Server Error' }
  } else {
    // Handle other errors (e.g., network errors)
    return { success: false, message: 'Internal Server Error' }
  }
}

const employeeService = {
  getEmployees: async (
    filter: NameFilter
  ): Promise<ApiSuccessResponse<IEmployee[]> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.get('/employees', {
        params: {
          ...filter
        }
      })

      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  addEmployee: async (
    data: EmployeeAddList
  ): Promise<ApiSuccessResponse<IEmployee[]> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.post('/employees', data)
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  editEmployee: async (
    id: string,
    data: Partial<IEmployee>
  ): Promise<ApiSuccessResponse<IEmployee> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.patch(`/employees/${id}`, data)
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  deleteEmployee: async (id: string) => {
    try {
      const response = await axiosObj.delete(`/employees/${id}`)
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },

  updateEmployeeInHmi: async () => {
    try {
      const response = await axiosObj.put('/hmi/employees')
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  updateEmployeeList: async (
    employeeIds: string[],
    isActive: boolean
  ): Promise<ApiSuccessResponse<{}> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.patch(`/employees/update/active-list`, {
        employeeIds,
        isActive
      })
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  }
}
export default employeeService
