import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'
import AddMachine from './AddMachineModal'

//assets
import { DeleteIcon, EditIcon } from '../../icons/Icons'

//utils
import { showNotification } from '../../utils/toast.utils'

//services
import machineService from '../../service/machine.service'

const FilterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-between',
  alignItems: 'center',
  columnGap: '16px',
  padding: '0'
}))

const Machine = () => {
  const [machines, setMachines] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [openAddModal, setOpenAddModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const fetchMachines = async () => {
    try {
      setIsLoading(true)
      const response = await machineService.getMachines()

      if (response.success) {
        setMachines(
          response.data.map((item, index) => ({
            index: index + 1,
            ...item
          }))
        )
      }
    } catch (err) {
      console.log(err)
      setMachines([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchMachines()
  }, [])

  const addMachine = async (machineInfo) => {
    if (!machineInfo.displayName) {
      showNotification('Machine label is required', 'error')
      return
    }
    if (!machineInfo.name) {
      showNotification('Machine value is required', 'error')
      return
    }

    const data = {
      name: machineInfo.name,
      displayName: machineInfo.displayName
    }

    try {
      setIsLoading(true)
      const response = await machineService.addMachine(data)
      if (response.data.success) {
        showNotification(`Machine added successfully`, 'success')
      } else {
        showNotification(response.data.message, 'error')
      }
      setOpenAddModal(false)
      fetchMachines()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const editMachine = async (id, machineInfo) => {
    if (!machineInfo.displayName) {
      showNotification('Machine label is required', 'error')
      return
    }
    if (!machineInfo.name) {
      showNotification('Machine value is required', 'error')
      return
    }

    const data = {
      name: machineInfo.name,
      displayName: machineInfo.displayName
    }

    try {
      setIsLoading(true)
      const response = await machineService.editMachine(id, data)
      if (response.status === 200) {
        showNotification(`Machine updated successfully`, 'success')
      } else {
        showNotification(response.data.message, 'error')
      }
      setOpenAddModal(false)
      fetchMachines()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
      setIsEditMode(false)
    }
  }

  const deleteMachine = async (machineId) => {
    if (!machineId) {
      showNotification('Machine id is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const response = await machineService.deleteMachine(machineId)
      if (response.data.success) {
        showNotification(`Machine deleted successfully`, 'success')
      } else {
        showNotification(response.data.message, 'error')
      }
      fetchMachines()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const partColumns = [
    {
      Header: 'Id',
      accessor: 'id'
    },
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Display name',
      accessor: 'displayName'
    },
    // {
    //   Header: "Created by",
    //   accessor: "createdBy",
    // },
    {
      Header: 'Actions',
      Cell: ({ cell: { row } }) => {
        return (
          <div
            style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}
          >
            <EditIcon
              onClickHandler={() => {
                setIsEditMode(true)
                setSelectedItem({ ...row.values })
                setOpenAddModal(true)
              }}
            />
            <DeleteIcon
              onClickHandler={() => {
                setSelectedItem({ ...row.values })
                setOpenDeleteModal(true)
              }}
            />
          </div>
        )
      }
    }
  ]

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'column',
          gap: '12px',
          borderBottom: '1px solid #eeeeee',
          backgroundColor: '#fff',
          padding: '12px 16px',
          zIndex: 100
        }}
      >
      <FilterContainer>
        <Button
          variant="contained"
          sx={{
            color: '#fff',
            textTransform: 'none'
          }}
          onClick={() => {
            setIsEditMode(false)
            setSelectedItem({})
            setOpenAddModal(true)
          }}
        >
          Add machine
        </Button>
      </FilterContainer>
      </Stack>
      <Box sx={{padding:'16px'}}>
      <StyledTableContainer>
        {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
        {!isLoading && machines.length === 0 && (
          <StyledEmptyHeader>There are no machine</StyledEmptyHeader>
        )}
        {!isLoading && machines.length > 0 && (
          <StyledTable
            pagination={false}
            columns={partColumns}
            data={machines}
            hiddenColumns={['id']}
          />
        )}
      </StyledTableContainer>
      </Box>
      {openAddModal && (
        <AddMachine
          closeHandler={() => setOpenAddModal(false)}
          isModalOpen={openAddModal}
          addPart={addMachine}
          editPart={editMachine}
          isEditMode={isEditMode}
          selectedPart={selectedItem}
        />
      )}
      {openDeleteModal && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`Do you want to delete the following machine ${selectedItem.type}`}
          buttonTitle="Delete"
          clickHandler={() => deleteMachine(selectedItem.id)}
          closeHandler={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  )
}

export default Machine
