import React, { useEffect, useState } from 'react'
import RejectionChartWithoutControls from '../../components/RejectionDoughnut/RejectionChartWithoutControls'
import { showNotification } from '../../utils/toast.utils'
import * as dashboardService from '../../service/dashboard.service'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'

const RejectionWrapperMachineWise = ({
  selectedDate,
  selectedMachine,
  selectedShift
}) => {
  const defaultPartFamily = {
    value: 'all',
    label: 'All part family'
  }
  const [isLoading, setIsLoading] = useState(false)
  const [rejectionData, setRejectionData] = useState({})
  const [rejectionReasonMap, setRejectionReasonMap] = useState({})
  const [selectedPartFamily, setSelectedPartFamily] =
    useState(defaultPartFamily)
  const [availablePartFamilyShiftWise, setAvailablePartFamilyShiftWise] =
    useState({})

  useEffect(() => {
    const fetchData = async () => {
      const params = {}

      if (selectedDate) {
        params.date = selectedDate
      }

      try {
        setIsLoading(true)
        const response = await dashboardService.getRejectionDataForDay(params)

        if (response.data.success) {
          const rejectionData = response.data.data.rejectionMachineWiseData
          setRejectionData(rejectionData)
          setRejectionReasonMap(response.data.rejectionReasonMap || {})

          const partFamilyOptions = {
            S1: [],
            S2: [],
            S3: [],
            day: []
          }

          Object.keys(rejectionData).forEach((machine) => {
            const machineData = rejectionData[machine]
            Object.keys(machineData).forEach((shift) => {
              if (!partFamilyOptions[shift]) {
                partFamilyOptions[shift] = []
              }
              Object.keys(machineData[shift]).forEach((partType) => {
                if (!partFamilyOptions[shift].includes(partType)) {
                  partFamilyOptions[shift].push(partType)
                }
                if (!partFamilyOptions.day.includes(partType)) {
                  partFamilyOptions.day.push(partType)
                }
              })
            })
          })

          setAvailablePartFamilyShiftWise(partFamilyOptions)
          setSelectedPartFamily({
            label: partFamilyOptions.day[0],
            value: partFamilyOptions.day[0]
          })
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (error) {
        console.error('Error fetching rejection data:', error)
        showNotification('Failed to fetch rejection data', 'error')
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [selectedDate])

  if (isLoading) {
    return <>Loading...</>
  }

  const machineData =
    selectedMachine &&
    selectedMachine.value &&
    rejectionData[selectedMachine.value]
      ? rejectionData[selectedMachine.value]
      : {}
  const shiftData =
    selectedShift && selectedShift.value && machineData[selectedShift.value]
      ? machineData[selectedShift.value]
      : {}

  const aggregateShiftData = (shiftData) => {
    return Object.keys(shiftData).reduce((acc, partFamily) => {
      if (!acc[partFamily]) {
        acc[partFamily] = {}
      }
      Object.keys(shiftData[partFamily]).forEach((reason) => {
        if (!acc[partFamily][reason]) {
          acc[partFamily][reason] = 0
        }
        acc[partFamily][reason] += shiftData[partFamily][reason]
      })
      return acc
    }, {})
  }

  const combinedShiftData =
    selectedShift.value === 'all'
      ? aggregateShiftData(machineData)
      : shiftData

  const filteredShiftData =
    selectedPartFamily.value === 'all'
      ? combinedShiftData
      : {
          [selectedPartFamily.value]: combinedShiftData[selectedPartFamily.value] || {}
        }

  const selectOptions =
    selectedShift && availablePartFamilyShiftWise[selectedShift.value]
      ? availablePartFamilyShiftWise[selectedShift.value].map((item) => ({
          label: item,
          value: item
        }))
      : []

  return (
    <>
      {Object.keys(filteredShiftData).length > 0 ? (
        <RejectionChartWithoutControls
          rejectionData={filteredShiftData}
          rejectionReasonMap={rejectionReasonMap}
          selectionComponent={
            <StyledSelectField
              label=""
              placeHolderText="Select part family"
              selectedValue={selectedPartFamily}
              selectOptions={selectOptions}
              onChangeHandler={(value) => setSelectedPartFamily(value)}
              isMulti={false}
              isSearchable={true}
              isDisabled={false}
              noOptionsMessage="No part family found"
            />
          }
        />
      ) : (
        <p>No data available for the selected machine and shift.</p>
      )}
    </>
  )
}

export default RejectionWrapperMachineWise
