import React from 'react'

import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'

const DivTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  marginBottom: '8px'
}))

const TypographyTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '24px',
  fontWeight: 500,
  textTransform: 'capitalize'
}))

const StyledPageHeader = (props) => {
  const { title } = props

  return (
    <DivTitleContainer>
      <TypographyTitle>{title}</TypographyTitle>
    </DivTitleContainer>
  )
}

export default StyledPageHeader
