//
import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import ReactSelect from 'react-select'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import Overview from './components/Overview'
import HourlyTrend from './components/HourlyTrend'
import Rejection from './components/Rejection'
import Idletime from './components/Idletime'
import Production from './components/Production'
import SingleMachineCard from './components/SingleMachineCard'
import DateSelector from '../../components/DateSelector'
import IdleTimeWrapperMachineWiseData from './IdleTimeWrapperMachineWiseData'

//utils
import { MACHINES } from '../../utils/constant.utils'
import * as DATE_UTILS from '../../utils/date.utils'
import { showNotification } from '../../utils/toast.utils'

//service
import liveDataService from '../../service/liveData.service'

const liveDataTabs = [
  { name: 'Overview', value: 'overview' },
  { name: 'Production', value: 'hourlyTrend' },
  { name: 'Rejection', value: 'rejection' },
  { name: 'Idletime', value: 'idletime' }
]
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '200px'
  }),
  control: (provided) => ({
    ...provided,
    height: '40px'
  })
}
const TabsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 24px 0 0',
  borderBottom: `1px solid #eee`
}))

const Tab = styled('div')(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  minWidth: 'fit-content',
  padding: '8px 16px',
  color: isActive ? theme.palette.primary.main : '#ccccd7',
  cursor: 'pointer',
  borderBottom: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : '1px solid transparent',
  '&:hover': {
    color: `${theme.palette.primary.main}`
  }
}))

const MachineStatus = () => {
  //default values
  let defaultTab = liveDataTabs[0].value
  let defaultMachine = {
    label: MACHINES[0].label,
    value: MACHINES[0].value
  }

  //hooks
  const navigate = useNavigate()
  const location = useLocation()

  //extract category from URL
  const params = new URLSearchParams(location.search)
  const tab = params.get('tab')
  const machine = params.get('machine')

  //If category present in the URL override the defaultJobCategory
  if (tab) {
    defaultTab = tab
  }

  if (machine) {
    defaultMachine =
      MACHINES.find((item) => item.value === machine) || defaultMachine
  }

  //component state
  const [allData, setAllData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectedMachine, setSelectedMachine] = useState(defaultMachine)
  const [activeSubTab, setActiveSubTab] = useState(defaultTab)
  const [availableShifts, setAvailableShifts] = useState([])
  const [selectedShift, setSelectedShift] = useState({
    label: 'S1',
    value: 'shift1'
  })
  const [workingDate, setWorkingDate] = useState(DATE_UTILS.workingDate())
  const [calenderDate, setCalenderDate] = useState(DATE_UTILS.workingDate())

  const fetchMachineData = async (workingDateInput) => {
    const params = {}

    if (selectedMachine.value !== 0) {
      params.machine = selectedMachine.value
    }

    if (workingDateInput) {
      params.date = workingDateInput
    }

    try {
      setIsLoading(true)
      const response = await liveDataService.getLiveData(params)
      // console.log(response);
      if (response.data.success) {
        const liveData = response.data.data
        setAllData(liveData)
        setAvailableShifts(
          Object.keys(liveData).map((shift) => ({
            label: shift[0].toUpperCase() + shift[shift.length - 1],
            value: shift
          }))
        )
      } else {
        showNotification(response.data.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    navigate(`?tab=${activeSubTab}&machine=${selectedMachine.value}`)
  }, [activeSubTab, selectedMachine, navigate])

  // useEffect(() => {
  //   if (availableShifts.length > 0) {
  //     setSelectedShift(availableShifts[availableShifts.length - 1])
  //   }
  // }, [availableShifts])

  useEffect(() => {
    fetchMachineData(workingDate)
  }, [selectedMachine, workingDate])

  const handleSubTabChange = (tab) => {
    setActiveSubTab(tab)
  }

  const dateChangeHandler = (e) => {
    setCalenderDate(e.target.value)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    setWorkingDate(calenderDate)
  }

  const prevNextHandler = (actionType) => {
    setCalenderDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate))
    setWorkingDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate))
  }

  let liveDataMinuteWise = []
  if (
    allData[selectedShift.value] &&
    allData[selectedShift.value]['minuteWisData']
  ) {
    liveDataMinuteWise = allData[selectedShift.value]['minuteWisData']
  }

  let liveDataHourlyGrouped = []
  if (
    allData[selectedShift.value] &&
    allData[selectedShift.value]['hourlyGroupedData']
  ) {
    liveDataHourlyGrouped = allData[selectedShift.value]['hourlyGroupedData']
  }

  return (
    <StyledPageContainer>
      <Box sx={{ padding: '16px', borderBottom: '1px solid #eee' }}>
        <SingleMachineCard machine={selectedMachine.value} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '12px 16px',
          borderBottom: '1px solid #eee'
        }}
      >
        <DateSelector
          date={calenderDate}
          dateChangeHandler={dateChangeHandler}
          submitHandler={submitHandler}
          prevNextHandler={prevNextHandler}
          isLoading={isLoading}
        />
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <ReactSelect
            styles={customStyles}
            placeholder="Select shift"
            options={availableShifts}
            value={selectedShift}
            onChange={(value) => setSelectedShift(value)}
          />
          <ReactSelect
            styles={customStyles}
            placeholder="Select Machine"
            options={MACHINES}
            value={selectedMachine}
            isSearchable
            noOptionsMessage={() => 'No machine Found'}
            onChange={(value) => setSelectedMachine(value)}
          />
        </Box>
      </Box>

      <TabsContainer>
        {React.Children.toArray(
          liveDataTabs.map((tab, i) => (
            <Tab
              isActive={activeSubTab === tab.value}
              onClick={() => handleSubTabChange(tab.value)}
            >
              {tab.name}
            </Tab>
          ))
        )}
      </TabsContainer>
      <Box sx={{ padding: '16px' }}>
        {activeSubTab === 'overview' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Overview
              isLoading={isLoading}
              liveData={liveDataMinuteWise}
              groupedData={liveDataHourlyGrouped}
            />
            <IdleTimeWrapperMachineWiseData
              selectedDate={workingDate}
              selectedMachine={selectedMachine}
              selectedShift={selectedShift}
            />
          </Box>
        )}
        {activeSubTab === 'hourlyTrend' && (
          <HourlyTrend
            isLoading={isLoading}
            groupedData={liveDataHourlyGrouped}
          />
        )}
        {activeSubTab === 'rejection' && (
          <Rejection
            isLoading={isLoading}
            groupedData={liveDataHourlyGrouped}
          />
        )}
        {activeSubTab === 'idletime' && (
          <Idletime isLoading={isLoading} groupedData={liveDataHourlyGrouped} />
        )}
        {activeSubTab === 'production' && <Production />}
      </Box>
    </StyledPageContainer>
  )
}

export default MachineStatus
