import React from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'

import BarChart from '../Charts/BarChart/BarChart'
import * as CHART_UTILS from '../Charts/BarChart/BarChart.constant'

const CardHeader = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px',
  borderBottom: '1px solid lightgray'
}))

const SelectBox = styled('select')(() => ({
  borderRadius: '4px',
  padding: '4px 8px'
}))

const OptionBox = styled('option')(() => ({
  padding: '4px 8px'
}))

const shiftMap = {
  shift1: { value: 'shift1', label: 'Shift-1' },
  shift2: { value: 'shift2', label: 'Shift-2' },
  daily: { value: 'daily', label: 'Day' }
}

const IdletimeTrend = ({ idleTimeTrendData }) => {
  const [isCombined, setIsCombined] = React.useState(false)
  const [selectedShift, setSelectedShift] = React.useState(shiftMap.daily.value)

  const chartTitle = 'Idle time trend'
  const chartLabels = idleTimeTrendData
    ? idleTimeTrendData.map((el) => el['date'])
    : []

  const dataGroup = idleTimeTrendData
    ? [
        {
          ...CHART_UTILS.BAR,
          //   type: 'line',
          //   lineTension: 0.1,
          label: shiftMap[selectedShift].label,
          data: idleTimeTrendData.map((el) => el[`${selectedShift}`]),
          backgroundColor: '#7afade',
          borderColor: '#04b890'
        }
      ]
    : []

  const combinedDataGroup = idleTimeTrendData
    ? [
        {
          ...CHART_UTILS.BAR,
          label: 'Shift-1',
          data: idleTimeTrendData.map((el) => el['shift1']),
          backgroundColor: '#7afade',
          borderColor: '#04b890'
        },
        {
          ...CHART_UTILS.BAR,
          label: 'Shift-2',
          data: idleTimeTrendData.map((el) => el['shift2']),
          backgroundColor: '#f5ae67',
          borderColor: '#b55d05'
        },
        {
          ...CHART_UTILS.BAR,
          label: 'Day',
          data: idleTimeTrendData.map((el) => el['daily']),
          backgroundColor: '#fa9ba6',
          borderColor: '#fa465b'
        }
      ]
    : []

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        border: '1px solid gray',
        borderRadius: '12px'
      }}
    >
      <CardHeader>
        <h3>{chartTitle}</h3>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'flex-end'
          }}
        >
          <SelectBox
            disabled={isCombined}
            value={selectedShift}
            onChange={(event) => setSelectedShift(event.target.value)}
          >
            {Object.values(shiftMap).map((item) => (
              <OptionBox value={item.value}>{item.label}</OptionBox>
            ))}
          </SelectBox>
        </Box>
      </CardHeader>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '0 12px'
          }}
        >
          <label style={{ marginLeft: 'auto' }}>Combined</label>
          <Switch
            checked={isCombined}
            onChange={(event) => setIsCombined(event.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Box>
        <BarChart
          chartTitle={chartTitle}
          chartLabels={chartLabels}
          dataGroup={isCombined ? combinedDataGroup : dataGroup}
          xLabel="DAYS"
          yLabel="Idletime"
        />
      </Box>
    </Box>
  )
}

export default IdletimeTrend
