import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import { nanoid } from 'nanoid'
import * as yup from 'yup'
import { useNavigate, useLocation } from 'react-router-dom'

//components
import FormTextField from '../../../components/FormTextField/FormTextField'
import ShiftConfigComp from './ShiftConfigComp'

//utils
import { showNotification } from '../../../utils/toast.utils'

//service
import shiftConfigService from '../../../service/shiftConfig.service'

const shiftConfigSchema = yup.object().shape({
  name: yup.string().required('Shift configuration name is required'),
  numberOfShifts: yup
    .number()
    .integer()
    .min(1)
    .max(3)
    .required('Number of shift is required'),
  shiftData: yup.array().of(
    yup
      .object()
      .shape({
        shift: yup.number(),
        start: yup.number().min(0).max(23).required(),
        end: yup.number().min(0).max(23).required(),
        breakTime: yup.number().min(0).max(100).required()
      })
      .required()
  ),
  isActive: yup.boolean()
})

const CreateShiftConfig = () => {
  //hooks
  const navigate = useNavigate()
  const location = useLocation()

  //extract category from URL
  const params = new URLSearchParams(location.search)
  const id = params.get('id')

  //component state
  const [name, setName] = useState('')
  const [numberOfShifts, setNumberOfShifts] = useState(0)
  const [shiftData, setShiftData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const addShift = () => {
    if (numberOfShifts > 3) {
      showNotification('Can not have more than 3 shifts', 'info')
      return
    }
    setShiftData((prev) => [
      ...prev,
      {
        id: nanoid(),
        shift: prev.length + 1,
        start: 0,
        end: 0,
        breakTime: 0
      }
    ])
    setNumberOfShifts((prev) => prev + 1)
  }

  const removeShift = (id) => {
    setShiftData((prev) => {
      const newData = prev.filter((data) => data.id !== id)
      return newData.map((data, index) => ({ ...data, shift: index + 1 }))
    })
    setNumberOfShifts((prev) => prev - 1)
  }

  const updateValue = (id, key, value) => {
    setShiftData((prev) => {
      const newData = prev.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            [key]: value
          }
        }
        return data
      })

      return newData
    })
  }

  const saveShiftConfig = async () => {
    //TODO: Validate
    let flag = false
    for (const shift of shiftData) {
      console.log({ shift })
      if (flag) {
        showNotification('Enter proper shift values', 'error')
        return // Return early from the function
        //break // Exit the loop
      }
      console.log({ start: shift.start })
      if (shift.start <= 0 || shift.end <= 0 || shift.breakTime <= 0) {
        flag = true
      }
    }

    const data = {
      name: name,
      numberOfShifts: numberOfShifts,
      shiftData: shiftData.map((data) => {
        delete data.id
        return data
      })
    }

    try {
      console.log('data before', data)
      const data1 = await shiftConfigSchema.validate(data)
      console.log('data after', data1)
    } catch (error) {
      console.log(error)
    }

    try {
      setIsLoading(true)
      let response
      if (id) {
        response = await shiftConfigService.updateShiftConfig(id, data)
      } else {
        response = await shiftConfigService.addShiftConfig(data)
      }
      console.log(response)
      if (response.data.success) {
        showNotification(`Shift config saved successfully`, 'success')
        navigate(`/admin/shift-config?tab=config-list`)
      } else {
        showNotification(response.data.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const getShiftConfig = async (id) => {
      try {
        setIsLoading(true)
        const response = await shiftConfigService.getShiftConfigById(id)

        if (response.data.success) {
          const data = response.data.data

          setName(data.name)
          setNumberOfShifts(data.numberOfShifts)
          setShiftData(
            data.shiftData.map((data) => ({
              ...data,
              id: nanoid()
            }))
          )
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    if (id) {
      getShiftConfig(id)
    }
  }, [])

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <div style={{ width: '100%', display: 'flex', flex: 1, gap: '16px' }}>
        <FormTextField
          enableMargin={true}
          autoFocus={true}
          label="Shift config name"
          placeholderText="Enter Shift config name.. Eg: 12hour shift or any unique idenfier"
          textValue={name}
          inputType="text"
          isRequired={true}
          onChangeHandler={(e) => setName(e.target.value)}
        />
        <FormTextField
          enableMargin={true}
          label="Number of shifts"
          placeholderText="Enter number of shifts Eg: 2"
          textValue={numberOfShifts}
          inputType="number"
          isRequired={true}
          isDisabled={true}
          onChangeHandler={(e) => setNumberOfShifts(e.target.value)}
        />
      </div>
      {React.Children.toArray(
        shiftData.map((data) => (
          <ShiftConfigComp
            data={data}
            removeShift={removeShift}
            updateValue={updateValue}
          />
        ))
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={addShift}
            disabled={numberOfShifts >= 3}
          >
            Add shift
          </Button>
          <Button
            disabled={shiftData.length === 0 || !name}
            variant="outlined"
            color="primary"
            onClick={saveShiftConfig}
          >
            {id ? 'Update' : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateShiftConfig
