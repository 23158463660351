import React, { useState } from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import DoughnutChart from '../Charts/DonutChart/DoughnutChart'

const idletimeDataMapper = (data, shift, idleReasonMap) => {
  const dataObj = data[shift]

  const chartData = []
  for (const property in dataObj) {
    chartData.push({
      reasonIdentifier: property,
      label: idleReasonMap[property],
      value: dataObj[property]
    })
  }

  chartData.sort((a, b) => b.value - a.value)

  return {
    labels: chartData.map((el) => el['label']),
    values: chartData.map((el) => el['value'])
  }
}

const Container = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid lightgray',
  borderRadius: '12px'
}))

const CardHeader = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px',
  borderBottom: '1px solid lightgray'
}))

const CardBody = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px'
}))

const CardFooter = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderTop: '1px solid lightgray',
  padding: '12px'
}))

const SelectBox = styled('select')(() => ({
  borderRadius: '4px',
  padding: '4px 8px'
}))

const OptionBox = styled('option')(() => ({
  padding: '4px 8px'
}))

const IdleTimeDoughnut = ({ idleTimeData, idleReasonMap }) => {
  //const [timeFrame, setTimeFrame] = useState('monthlyData')
  const [shift, setShift] = useState('daily')

  const chartData = idletimeDataMapper(idleTimeData, shift, idleReasonMap)

  const totalCount = chartData
    ? chartData['values'].reduce((accu, element) => accu + element, 0)
    : 0

  return (
    <Container>
      <CardHeader>
        <h3>Idletime data</h3>
        <Box>
          <Box>
            <SelectBox
              id="shiftNum"
              value={shift}
              onChange={(event) => setShift(event.target.value)}
            >
              <OptionBox value="shift1">Shift1</OptionBox>
              <OptionBox value="shift2">Shift2</OptionBox>
              <OptionBox value="daily">Day</OptionBox>
            </SelectBox>
          </Box>
          {/* <Box>
            <select
              id="timeFrame"
              value={timeFrame}
              onChange={(event) => setTimeFrame(event.target.value)}
            >
              <option value="dailyData">Day</option>
              <option value="monthlyData">Month</option>
            </select>
          </Box> */}
        </Box>
      </CardHeader>
      <CardBody>
        <DoughnutChart
          chartTitle=""
          chartLabels={chartData.labels}
          dataGroup={chartData.values}
        />
      </CardBody>
      <CardFooter>
        <h4>
          Total Idletime = {totalCount && (totalCount / (60 * 24)).toFixed(1)}{' '}
          Days
          {/* {timeFrame === 'monthlyData' && !isForMachine ? (
            <span>
              : {totalCount && (totalCount / (60 * 24)).toFixed(1)} Days
            </span>
          ) : (
            <span>: {totalCount && (totalCount / 60).toFixed(1)} Hours</span>
          )}
          {isForMachine && (
            <span>: {totalCount && totalCount.toFixed(0)} Minutes</span>
          )} */}
        </h4>
      </CardFooter>
    </Container>
  )
}

export default IdleTimeDoughnut
