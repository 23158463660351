import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledPageHeader from '../../components/StyledPageHeader/StyledPageHeader'
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import { useDateSelectorContext } from '../../context/DateSelectorContext'

//utils
import { showNotification } from '../../utils/toast.utils'
import { MACHINES } from '../../utils/constant.utils'
import * as DATE_UTILS from '../../utils/date.utils'

//services
import liveDataService from '../../service/liveData.service'


// TODO: Enabled all machines update BE API to support machine grouping
// { label: 'All machines', value: 0 }
const machineOptions = [...MACHINES]

const EnergyRecord = () => {
  const [energyRecordRows, setEnergyRecordRows] = useState([])
  const [machine, setMachine] = useState(machineOptions[0])
  const { selectedDate } = useDateSelectorContext()
  const [availableShifts, setAvailableShifts] = useState([])
  const [selectedShift, setSelectedShift] = useState({
    label: 'All shifts',
    value: 0
  })
  const [isLoading, setIsLoading] = useState(false)
  const [startIndex, setStartIndex] = useState(0);
  const rowsPerPage = 10;

  const handlePrevious = () => {
    if (startIndex - rowsPerPage >= 0) {
      setStartIndex(startIndex - rowsPerPage);
    }
  };
  const handleNext = () => {
    if (startIndex + rowsPerPage < filteredRows.length) {
      setStartIndex(startIndex + rowsPerPage);
    }
  };

  const fetchEnergyRecords = async () => {
    const params = {}

    if (selectedDate) {
      params.date = selectedDate
    }

    try {
      setIsLoading(true)
      const energyRecordResponse =
        await liveDataService.getEnergyRecords(params)
      console.log(energyRecordResponse)
      const data = energyRecordResponse.data.data
      const availableShifts = []
      const partsData = data.map((data, i) => {
        const shiftValue = `S${data.shift}`
        if (!availableShifts.includes(shiftValue)) {
          availableShifts.push(shiftValue)
        }
        return {
          index: i + 1,
          machine: data.machine,
          time:data.time,
          shift: shiftValue,
          tag1: data.tag1,
          tag2: data.tag2,
          tag3: data.tag3,
          tag4: data.tag4,
          tag5: data.tag5,
          tag6: data.tag6,
         
        }
      })

      setAvailableShifts([
        {
          label: 'All shifts',
          value: 0
        },
        ...availableShifts.map((shift) => {
          return { label: shift, value: shift }
        })
      ])
      setEnergyRecordRows(partsData)
    } catch (err) {
      console.log(err)
      setEnergyRecordRows([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (machine === null) {
      return
    }
    fetchEnergyRecords()
  }, [selectedDate])

  // const clearAll = async () => {
  //   setSelectedDate(DATE_UTILS.todaysDate())
  //   setMachine(machineOptions[0])
  //   await fetchEnergyRecords()
  // }

  //1. Create search object
  const searchObj = {
    // ...(machine &&
    //   (machine.value !== 'all' || machine.value === 0) && {
    //     machine: machine.value
    //   }),
    ...(selectedShift &&
      selectedShift.value &&
      selectedShift.value !== 0 && {
        shift: selectedShift.value
      })
  }

  //2. Extract the keys into an array
  const keysToChecked = Object.keys(searchObj)
  console.log('keysToChecked', keysToChecked, searchObj)

  //3. filter
  let filteredRows = energyRecordRows
  if (keysToChecked.length > 0) {
    filteredRows = energyRecordRows.filter((energyRecordRow) => {
      return keysToChecked.every(
        (key) => searchObj[key] === energyRecordRow[key]
      )
    })
  }
  const formatToTwoDecimalPlaces = (value) => {
    if (typeof value === 'number') {
      return value.toFixed(2);
    }
    return value;
  };

  const energyRecordColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'Machine',
      accessor: 'machine'
    },
    {
      Header: 'Time',
      accessor: 'time'
    },
    {
      Header: 'Shift',
      accessor: 'shift'
    },
    {
      Header: 'Voltge [V]',
      accessor: 'tag1',
      Cell: ({ value }) => formatToTwoDecimalPlaces(value)
    },
    {
      Header: 'Current [A]',
      accessor: 'tag2',
      Cell: ({ value }) => formatToTwoDecimalPlaces(value)
    },
    {
      Header: 'Power [Kw]',
      accessor: 'tag3',
      Cell: ({ value }) => formatToTwoDecimalPlaces(value)
    },
  
    {
      Header: 'Frequency [Hz]',
      accessor: 'tag4',
      Cell: ({ value }) => formatToTwoDecimalPlaces(value)
    },
    {
      Header: 'Energy [KWh]',
      accessor: 'tag5',
      Cell: ({ value }) => formatToTwoDecimalPlaces(value)
    },
    {
      Header: 'Power Factor',
      accessor: 'tag6',
      Cell: ({ value }) => formatToTwoDecimalPlaces(value)
    },
   
  ]
  const buttonStyle = {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #ccccd7',
    padding: '6px 12px',
    margin: '0 10px',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold'
  };
  

  const buttonContainerStyle = {
    textAlign: 'right',
    marginTop: '20px',
  };

  const TotalEnergyDisplay = ({ filteredRows }) => {
    // Get the first and last energy values from filtered rows
    const firstEnergy = filteredRows.length > 0 ? parseFloat(filteredRows[0].tag5) : 0;
    const lastEnergy = filteredRows.length > 0 ? parseFloat(filteredRows[filteredRows.length - 1].tag5) : 0;
  
    // Calculate the difference
    const energyDifference = (lastEnergy - firstEnergy).toFixed(3);
  
    return (
      <div style={{ marginTop: '20px' }}>
        <strong>Energy Difference:</strong> {energyDifference} KWh
      </div>
    );
  };

  return (
    <>
       <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '24px',
          padding: '16px',
          backgroundColor: '#fff',
          borderBottom: '1px solid #eeeeee'
        }}
      >
          <StyledSelectField
            label=""
            placeHolderText="Select shift"
            selectedValue={selectedShift}
            selectOptions={availableShifts}
            onChangeHandler={(value) => setSelectedShift(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No shift found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select machine"
            selectedValue={machine}
            selectOptions={machineOptions}
            onChangeHandler={(value) => setMachine(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No machine found"
          />
          {/* <Button
            //startIcon={<ClearIcon />}
            variant="contained"
            sx={{
              color: '#fff'
            }}
            onClick={clearAll}
          >
            Clear
          </Button> */}
        </Stack>
        <Stack
        sx={{
          gap: '16px',
          padding: '16px'
        }}
      >
      <StyledTableContainer>
        {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
        {!isLoading && filteredRows.length === 0 && (
          <StyledEmptyHeader>There are no energy record</StyledEmptyHeader>
        )}
        {/* {!isLoading && filteredRows.length > 0 && (
          <StyledTable
            pagination={false}
            columns={energyRecordColumns}
            data={filteredRows}
            hiddenColumns={['machine']}
          />
        )} */}
         <TotalEnergyDisplay filteredRows={filteredRows} />
        {!isLoading && filteredRows.length > 0 && (
          <div>
            <StyledTable
              pagination={false}
              columns={energyRecordColumns}
              data={filteredRows.slice(startIndex, startIndex + rowsPerPage)}
              hiddenColumns={['machine']}
              
            />
          </div>
        )}
      </StyledTableContainer>
      <div style={buttonContainerStyle}>
        <button onClick={handlePrevious} disabled={startIndex === 0} style={buttonStyle}>
          Prev
        </button>
        <button onClick={handleNext} disabled={startIndex + rowsPerPage >= filteredRows.length} style={buttonStyle}>
          Next
        </button>
      </div>
      </Stack>
    </>
  )
}

export default EnergyRecord

