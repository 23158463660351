import React, { useState } from 'react'
import { styled } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

//service
import hmiUpdateService from '../../service/hmiUpdate.service'

//utils
import { showNotification } from '../../utils/toast.utils'

const BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL
const hmiParameters = [
  {
    name: 'Part number',
    parameter: 'part name',
    path: `${BASE_URL}/v1/hmi/parts`,
    params: {
      parameter: 'partName',
      columnName: 'name'
    }
  },
  {
    name: 'Operation type',
    parameter: 'part type',
    path: `${BASE_URL}/v1/hmi/parts`,
    params: {
      parameter: 'partType',
      columnName: 'type'
    }
  },
  {
    name: 'Employee name',
    parameter: 'Employee name',
    path: `${BASE_URL}/v1/hmi/employees`,
    params: {
      parameter: 'operator',
      columnName: 'name'
    }
  },
  {
    name: 'Employee Id',
    parameter: 'Employee Id',
    path: `${BASE_URL}/v1/hmi/employees`,
    params: {
      parameter: 'operatorId',
      columnName: 'id'
    }
  },
  {
    name: 'Line inspector',
    parameter: 'line inspector',
    path: `${BASE_URL}/v1/hmi/employees`,
    params: {
      parameter: 'lineInspector',
      columnName: 'name'
    }
  },
  {
    name: 'Supervisor',
    parameter: 'supervisor',
    path: `${BASE_URL}/v1/hmi/employees`,
    params: {
      parameter: 'supervisor',
      columnName: 'name'
    }
  }
]

const CardContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px'
}))

const Card = styled('div')(({  isSelected }) => ({
  position: 'relative',
  width: '300px',
  height: '100px',
  display: 'flex',
  justifyContent: 'Center',
  alignItems: 'center',
  padding: '8px 16px',
  backgroundColor: isSelected ? '#ccccd7' : '#fff',
  color: '#000',
  borderRadius: '8px',
  cursor: 'pointer',
  border: '1px solid rgb(18 18 19 /30%)',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: '#ccccd7',
    color: 'black'
  }
}))

const Title = styled('div')(() => ({
  textAlign: 'center',
  overflow: 'hidden', //Don't remove
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}))

const LoadingContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  rowGap: '16px',
  fontWeight: 600
}))

const UpdateHMI = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentParameter, setCurrentParameter] = useState(false)

  const updateHmiParameter = async (path, parameter, params) => {
    try {
      setIsLoading(true)
      setCurrentParameter(parameter)
      const updateResponse = await hmiUpdateService.updateHmiParameter(
        path,
        params
      )
      if (updateResponse.success) {
        showNotification(`updated in ${parameter} HMI successfully`, 'success')
      } else {
        showNotification(updateResponse.data.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }
  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
        <p>Updating {currentParameter} ....</p>
      </LoadingContainer>
    )
  }

  return (
    <CardContainer>
      {hmiParameters &&
        React.Children.toArray(
          hmiParameters.map((parameter) => (
            <Card
              onClick={() =>
                updateHmiParameter(
                  parameter.path,
                  parameter.parameter,
                  parameter.params
                )
              }
            >
              <Title>{parameter.name}</Title>
            </Card>
          ))
        )}
    </CardContainer>
  )
}

export default UpdateHMI
