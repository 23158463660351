import React, { useRef } from 'react'
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title
} from 'chart.js'

import { Bar } from 'react-chartjs-2'

import * as CHART_UTILS from './BarChart.constant'
import 'chart.js/auto' // ADD THIS

// console.log("Chart", {Chart, LineController})

Chart.register(LineController, LineElement, PointElement, LinearScale, Title)

const BarChart = ({
  chartLabels,
  dataGroup,
  yLabel,
  xLabel,
  minValue,
  maxValue,
  stepValue
}) => {
  const ref = useRef()

  console.log({ chartLabels, dataGroup })

  const data = {
    labels: chartLabels,
    datasets: dataGroup
  }

  // return <> dkjsakldj</>;
  return (
    <Bar
      height="40%"
      width="100%"
      ref={ref}
      data={data}
      options={{
        responsive: true,
        plugins: {
          tooltip: CHART_UTILS.TOOLTIP,
          legend: {
            ...CHART_UTILS.LEGEND,
            display: false
          }
        },
        scales: {
          x: {
            ...CHART_UTILS.X_SCALE,
            title: { ...CHART_UTILS.X_SCALE.title, text: xLabel }
          },
          y: {
            ...CHART_UTILS.Y_SCALE,
            title: { ...CHART_UTILS.Y_SCALE.title, text: yLabel },
            min: minValue,
            max: maxValue,
            ticks: {
              stepSize: stepValue
            }
          }
        }
      }}
    />
  )
}

export default BarChart

// import React from "react";
// import { Bar } from "react-chartjs-2";

// import * as CHART_UTILS from "./BarChart.constant";

// function BarChart({ chartLabels, dataGroup, yLabel, XLabel }) {
//   const barChart = dataGroup ? (
//     <Bar
//       data={{
//         labels: [],
//         datasets: [{}],
//       }}
//       height={300}
//       width={900}
//       options={{
//         plugins: {
//           // title: { display: true, text: `${chartTitle}` },
//           tooltip: CHART_UTILS.TOOLTIP,
//           legend: CHART_UTILS.LEGEND,
//         },

//         scales: {
//           x: CHART_UTILS.X_SCALE,
//           y: {
//             ...CHART_UTILS.Y_SCALE,
//             title: { ...CHART_UTILS.Y_SCALE.title, text: yLabel },
//           },
//         },
//       }}
//     />
//   ) : "No data available";

//   return <div>{barChart}</div>;
// }

// export default BarChart;
