import React, { memo } from 'react'
import { styled, useMediaQuery } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom'

//components
import UserInfo from '../components/UserProfile/UserProfile'

//assets
import logo from '../assets/logo.png'

const App = styled('main')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#424242',
  backgroundColor: '#fff'
}))

const Logo = styled('div')(({ theme }) => ({
  marginTop: '24px',
  maxWidth: '100%'
}))

const Profile = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: '20px',
  top: '20px'
}))

const CardWrapper = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  gap: '32px',
  padding: '16px 0'
}))

const Card = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '300px',
  height: '100px',
  display: 'flex',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
  color: '#000',
  borderRadius: '8px',
  border: '1px solid #d9d9d9',
  cursor: 'pointer',
  fontWeight: 500,
  fontSize: '20px',
  '&:hover': { backgroundColor: '#fafafa' }
}))

const DPS_MODULES = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    enabled: true
  },
  {
    name: 'Admin',
    path: '/admin',
    enabled: true
  }
]

const EntryLayout = () => {
  const navigate = useNavigate()
  const unitChangeHandler = (item) => {
    if (item.enabled) {
      navigate(item.path)
    } else {
      //notify through
    }
  }

  return (
    <App>
      <Profile>
        <UserInfo />
      </Profile>
      <Logo>
        <img alt="logo" src={logo} />
      </Logo>
      <h1>Welcome to Industry 4.0 solutions</h1>
      <CardWrapper>
        {DPS_MODULES.map((item) => (
          <Card key={item.path} onClick={() => unitChangeHandler(item)}>
            {item.name}
          </Card>
        ))}
      </CardWrapper>
    </App>
  )
}

export default memo(EntryLayout)
