import React, { useState, useEffect, useMemo } from 'react'
import { Typography, styled } from '@mui/material'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

//components
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import { useDateSelectorContext } from '../../context/DateSelectorContext'

//utils
import * as DATE_UTILS from '../../utils/date.utils'
import { showNotification } from '../../utils/toast.utils'

//services
import * as dashboardService from '../../service/dashboard.service'

const CustomForm = styled('form')(() => ({
  display: 'flex',
  flex: 1,
  marginTop: '32px'
}))

const CustomInput = styled('input')(() => ({
  height: '40px',
  margin: '2px',
  minWidth: '150px',
  borderRadius: '8px',
  cursor: 'pointer',
  padding: '8px'
}))

const CustomButton = styled('button')(() => ({
  height: '40px',
  margin: '2px',
  minWidth: '150px',
  borderRadius: '8px',
  cursor: 'pointer'
}))
const PartWise = () => {
  //component state
  const [isLoading, setIsLoading] = useState(false)
  const [partWiseData, setPartWiseData] = useState([])
  const [partNumbers, setPartNumbers] = useState([])
  const [operationTypes, setOperationTypes] = useState([])
  const [availableShifts, setAvailableShifts] = useState([])
  const { selectedDate } = useDateSelectorContext()
  const [selectedShift, setSelectedShift] = useState({
    label: 'All shifts',
    value: 0
  })
  const [selectedPartNumber, setSelectedPartNumber] = useState({
    label: 'All parts',
    value: 'all'
  })
  const [selectedOperationType, setSelectedOperationType] = useState({
    label: 'All operations',
    value: 'all'
  })

  const fetchData = async () => {
    const params = {}

    if (selectedDate) {
      params.date = selectedDate
    }

    try {
      setIsLoading(true)

      const response = await dashboardService.getDailyReportPartWiseV2(params)
      console.log(response)
      if (response.data.success) {
        const partWiseData = response.data.data.productionInfo
        const partNumbers = response.data.data.partNumbers
        const operationTypes = response.data.data.operationTypes
        const availableShifts = response.data.data.availableShifts

        setPartWiseData(partWiseData)
        setAvailableShifts([
          {
            label: 'All shifts',
            value: 0
          },
          ...availableShifts.map((shift) => {
            return { label: shift, value: shift }
          })
        ])
        setPartNumbers([
          {
            label: 'All parts',
            value: 0
          },
          ...partNumbers.map((item) => ({ label: item, value: item }))
        ])
        setOperationTypes([
          {
            label: 'All operation',
            value: 0
          },
          ...operationTypes.map((item) => ({ label: item, value: item }))
        ])
      } else {
        showNotification(response.data.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [selectedDate])

  let formattedData = [...partWiseData]

  //1. Create search object
  const searchObj = {
    ...(selectedShift &&
      selectedShift.value &&
      selectedShift.value !== 'all' && {
        shift: selectedShift.value
      }),
    ...(selectedPartNumber &&
      selectedPartNumber.value &&
      selectedPartNumber.value !== 'all' && {
        partName: selectedPartNumber.value
      }),
    ...(selectedOperationType &&
      selectedOperationType.value &&
      selectedOperationType.value !== 'all' && {
        partType: selectedOperationType.value
      })
  }

  //2. Extract the keys into an array
  const keysToCheck = Object.keys(searchObj)

  //3. filter
  if (keysToCheck.length > 0) {
    formattedData = formattedData.filter((productionData) => {
      return keysToCheck.every((key) => searchObj[key] === productionData[key])
    })
  }

  const totalPartCount = formattedData.reduce(
    (acc, cv) => acc + Number(cv.partCount),
    0
  )
  const totalRejectionCount = formattedData.reduce(
    (acc, cv) => acc + Number(cv.rejectionCount),
    0
  )

  const columns = [
    // {
    //   Header: 'S.No',
    //   accessor: 'index'
    // },
    {
      Header: 'Shift',
      accessor: 'shift'
    },
    {
      Header: 'Part number',
      accessor: 'partName'
    },
    {
      Header: 'Operation',
      accessor: 'partType'
    },
    {
      Header: 'Part count',
      accessor: 'partCount'
    },
    {
      Header: 'Rejection',
      accessor: 'rejectionCount'
    }
  ]

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '24px',
          padding: '16px',
          backgroundColor: '#fff',
          borderBottom: '1px solid #eeeeee'
        }}
      >
          <StyledSelectField
            label=""
            placeHolderText="Select shift"
            selectedValue={selectedShift}
            selectOptions={availableShifts}
            onChangeHandler={(value) => setSelectedShift(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No shift found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select part number"
            selectedValue={selectedPartNumber}
            selectOptions={partNumbers}
            onChangeHandler={(value) => setSelectedPartNumber(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No part found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select operation"
            selectedValue={selectedOperationType}
            selectOptions={operationTypes}
            onChangeHandler={(value) => setSelectedOperationType(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No operation found"
          />
        </Stack>
        <Stack
        sx={{
          gap: '16px',
          padding: '16px'
        }}
      >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          gap: '32px'
        }}
      >
        <Typography sx={{ fontWeight: 400 }}>
          Total part count:{' '}
          <span style={{ fontWeight: 600 }}>{totalPartCount}</span>
        </Typography>
        <Typography sx={{ fontWeight: 400 }}>
          Total rejection count:{' '}
          <span style={{ fontWeight: 600 }}>{totalRejectionCount}</span>
        </Typography>
      </Box>
      <StyledTableContainer>
        {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
        {!isLoading && formattedData.length === 0 && (
          <StyledEmptyHeader>
            There are no parts available, try selecting different date.
          </StyledEmptyHeader>
        )}
        {formattedData.length > 0 && (
          <StyledTable
            pagination={false}
            columns={columns}
            data={formattedData}
            hiddenColumns={[]}
          />
        )}
      </StyledTableContainer>
      </Stack>
    </>
  )
}

export default PartWise
