import React, { useEffect, useState } from 'react'
import IdleTimeDoughnut from '../../components/IdleTimeDoughnut/ChartWithoutControls'
import { showNotification } from '../../utils/toast.utils'
import * as dashboardService from '../../service/dashboard.service'

const IdleTimeWrapperMachineWiseData = ({
  selectedDate,
  selectedMachine,
  selectedShift
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [idletimeData, setIdletimeData] = useState({})
  const [idleReasonMap, setIdleReasonMap] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const params = {}

      if (selectedDate) {
        params.date = selectedDate
      }
      if (selectedMachine && selectedMachine.value) {
        params.machine = selectedMachine.value
      }
      if (selectedShift && selectedShift.value) {
        params.shift = selectedShift.value
      }

      try {
        setIsLoading(true)

        const response = await dashboardService.getIdletimeDataForDay(params)

        if (response.data.success) {
          console.log(
            'Full Idletime Data:',
            response.data.data.idleTimeMachineWiseData
          )
          setIdletimeData(response.data.data.idleTimeMachineWiseData)
          setIdleReasonMap(response.data.data.idleReasonMap)
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log(err)
        showNotification('Failed to fetch data', 'error')
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [selectedDate, selectedMachine, selectedShift])

  const shiftKeyMapping = {
    shift1: 'S1',
    shift2: 'S2'
  }

  const shiftKey =
    shiftKeyMapping[selectedShift.value] || selectedShift.value.toUpperCase()
  const idleTimeDataForSelectedMachineAndShift =
    idletimeData[selectedMachine.value]?.[shiftKey]
  console.log(
    'Idle Time Data for Selected Machine and Shift:',
    idleTimeDataForSelectedMachineAndShift
  )

  if (isLoading) {
    return <>Loading..</>
  }
  
  return (
    <div>
      {idleTimeDataForSelectedMachineAndShift ? (
        <IdleTimeDoughnut
          idleTimeData={idleTimeDataForSelectedMachineAndShift}
          idleReasonMap={idleReasonMap}
          date={selectedDate}
        />
      ) : (
        <p>No data available for the selected machine and shift.</p>
      )}
    </div>
  )
}

export default IdleTimeWrapperMachineWiseData
