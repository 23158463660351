export const LEGEND = {
    display: true,
    fullSize: false,
    position: 'right',
    align: 'center',
    maxWidth: 300,
    labels: {
      boxWidth: 20,
      boxHeight: 10,
      font: { size: 14 }
    }
  }
  
  export const TOOTIP = {
    xAlign: 'center',
    yAlign: 'bottom',
    titleAlign: 'center',
    bodyAlign: 'center',
    backgroundColor: '#282c34',
    bodyColor: '#fff'
  }
  
  export const DOUGHNUT = {
    backgroundColor: [
      '#003f5c',
      '#2f4b7c',
      '#665191',
      '#a05195',
      '#d45087',
      '#f95d6a',
      '#ff7c43',
      '#ffa600',
      '#eed655',
      '#ddf633',
      '#003fff',
      '#2f4fff',
      '#665fff',
      '#a05fff',
    ],
    borderColor: '#fff',
    borderWidth: 0.5,
    hoverOffset: 10,
    hoverBorderWidth: 1,
    radius: 150,
    cutout: '60%'
  }
  
  export const TITLE = {
    display: true,
    text: ``,
    font: {
      size: 15,
      style: 'normal',
      weight: 400
    }
  }
  