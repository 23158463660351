import React from 'react'

const DateSelectorContext =React.createContext() 
export function useDateSelectorContext(){
    const dateSelectorData = React.useContext(DateSelectorContext)
    if(dateSelectorData === undefined){
        throw new Error(
            'useDateSelectorContext must be used with in DateSelectorContextProvider'
        )
    }
    return dateSelectorData
}
export default DateSelectorContext
