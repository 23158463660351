import type { Unit } from '../types/common'

const MACHINES_MAP = {
  unit4: [
    { label: 'Anantha', value: 'ANANTHA' },
    { label: 'Dasharatha', value: 'DASHARATHA' },
    { label: 'Digvijaya', value: 'DIGVIJAYA' },
    { label: 'Krishna', value: 'KRISHNA' },
    { label: 'Pavanaputra', value: 'PAVANAPUTRA' },
    { label: 'Shri Manjunatha', value: 'SHRIMANJUNATHA' },
    { label: 'Subrahmanya', value: 'SUBRAHMANYA' },
    { label: 'Trivikrama', value: 'TRIVIKRAMA' },
    { label: 'Vijaya', value: 'VIJAYA' },
    { label: 'Vikrama', value: 'VIKRAMA' },
    { label: 'Vishnu', value: 'VISHNU' }
  ],
  unit5: [
    { label: 'Balarama', value: 'BALARAMA' },
    { label: 'Balabheema', value: 'BALABHEEMA' }
  ],
  test: [
    { label: 'MC1', value: 'MC1' },
    { label: 'MC2', value: 'MC2' },
    { label: 'Balabheema', value: 'BALABHEEMA' },
    { label: 'Balarama', value: 'BALARAMA' }
  ]
}

if (MACHINES_MAP[process.env.REACT_APP_UNIT as Unit] === undefined) {
  throw new Error(
    'Invalid Unit name, Check your .env file, Possible values: unit4, unit5, test or add to MACHINES_MAP'
  )
}

export const MACHINES = MACHINES_MAP[process.env.REACT_APP_UNIT as Unit]

export const ROLES = [
  {
    value: 'operator',
    label: 'Operator'
  },
  {
    value: 'lineInspector',
    label: 'line Inspector'
  },
  {
    value: 'supervisor',
    label: 'Supervisor'
  }
]
