import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import DateSelector from '../../components/DateSelector'
import Dashboard from './Dashboard'
import Demography from './Demography'
import MachineWise from './MachineWise'
import PartWiseMonthly from './PartWiseMonthly'

import MonthSelectorContext from '../../context/MonthSelectorContext'
import * as DATE_UTILS from '../../utils/date.utils'

const pageTabs = {
  DASHBOARD: { name: 'Dashboard', value: 'dashboard' },
  DEMOGRAPHY: { name: 'Demography', value: 'demography' },
  MACHINE_WISE: { name: 'Machine wise', value: 'machine-wise' },
  PART_WISE_MONTHLY: { name: 'Part wise monthly', value: 'part-wise-monthly' },
  OPERATOR_INFO: { name: 'Operator info', value: 'operator-info' }
}

const TabsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignSelf: 'flex-end'
}))

const Tab = styled('div')(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  minWidth: 'fit-content',
  padding: '8px',
  color: isActive ? theme.palette.primary.main : '#ccccd7',
  cursor: 'pointer',
  borderBottom: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : '1px solid transparent',
  '&:hover': {
    color: `${theme.palette.primary.main}`
  }
}))

const ManageData = () => {
  //default values
  let defaultTab = pageTabs.DASHBOARD.value

  //hooks
  const navigate = useNavigate()
  const location = useLocation()

  //extract category from URL
  const params = new URLSearchParams(location.search)
  const tab = params.get('tab')

  if (tab) {
    defaultTab = tab
  }

  //local
  const [workingDate, setWorkingDate] = useState(
    DATE_UTILS.currentMonthAndYear()
  )
  const [calenderDate, setCalenderDate] = useState(
    DATE_UTILS.currentMonthAndYear()
  )
  const [activeSubTab, setActiveSubTab] = useState(defaultTab)
  const [searchString, setSearchString] = useState('')

  const dateChangeHandler = (e) => {
    setCalenderDate(e.target.value)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    setWorkingDate(calenderDate)
  }

  const prevNextHandler = (actionType) => {
    setCalenderDate((prevDate) =>
      DATE_UTILS.updateMonthAndYear(actionType, prevDate)
    )
    setWorkingDate((prevDate) =>
      DATE_UTILS.updateMonthAndYear(actionType, prevDate)
    )
  }

  useEffect(() => {
    navigate(`?tab=${activeSubTab}`)
    // if (searchString && searchString !== '') {
    //   navigate(`?tab=${activeSubTab}&${searchString}`)
    // } else {
    //   navigate(`?tab=${activeSubTab}`)
    // }
  }, [activeSubTab, searchString])

  const handleSubTabChange = (tab, searchString) => {
    setActiveSubTab(tab)
    setSearchString(searchString)
  }

  return (
    <StyledPageContainer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid #eeeeee`
        }}
      >
        <TabsContainer>
          {React.Children.toArray(
            Object.values(pageTabs).map((tab, i) => (
              <Tab
                isActive={activeSubTab === tab.value}
                onClick={() => handleSubTabChange(tab.value)}
              >
                {tab.name}
              </Tab>
            ))
          )}
        </TabsContainer>
        <Box sx={{ padding: '12px 24px' }}>
          <DateSelector
            date={calenderDate}
            dateChangeHandler={dateChangeHandler}
            submitHandler={submitHandler}
            prevNextHandler={prevNextHandler}
            type="month"
            disabled={false}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 56.4px)',
          overflowY: 'auto'
        }}
      >
        <MonthSelectorContext.Provider
          value={{
            selectedDate: workingDate
          }}
        >
          {activeSubTab === pageTabs.DASHBOARD.value && <Dashboard />}
          {activeSubTab === pageTabs.DEMOGRAPHY.value && (
            <Demography
              tabChange={(searchString) =>
                handleSubTabChange(pageTabs.MACHINE_WISE.value, searchString)
              }
            />
          )}
          {activeSubTab === pageTabs.MACHINE_WISE.value && <MachineWise />}
          {activeSubTab === pageTabs.PART_WISE_MONTHLY.value && <PartWiseMonthly />}
          {activeSubTab === pageTabs.OPERATOR_INFO.value && <>Coming soon...</>}
        </MonthSelectorContext.Provider>
      </Box>
    </StyledPageContainer>
  )
}

export default ManageData
