import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'

import StyledModal from '../../components/StyledModal/StyledModal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

import { EmployeeData, EmployeeAddList } from '../../types/employee'
import { OptionItem } from '../../types/common'

import { ROLES } from '../../utils/constant.utils'

interface Props {
  isModalOpen: boolean
  closeHandler: () => void
  selectedEmployee: EmployeeData | null
  addEmployee?: (EmployeeData: EmployeeAddList) => void
  editEmployee?: (id: string, newData: EmployeeAddList) => void
  isEditMode?: boolean
  isLoading?: boolean
}

// const ROLES = [
//   { value: 'operator', label: 'Operator' },
//   { value: 'lineInspector', label: 'Line Inspector' },
//   { value: 'supervisor', label: 'Supervisor' }
// ];

const EmployeeUploadModal = (props: Props) => {
  let defaultEmployeeId = ''
  let defaultEmployeeRole: OptionItem = {
    value: 0,
    label: 'Select operation type'
  }
  let defaultEmployeeName = ''
  let defaultIsActive = true

  if (props.isEditMode && props.selectedEmployee) {
    defaultEmployeeId = props.selectedEmployee.id.toString()
    defaultEmployeeName = props.selectedEmployee.name
    defaultIsActive = props.selectedEmployee.isActive

    defaultEmployeeRole.value = props.selectedEmployee.role
    defaultEmployeeRole.label = props.selectedEmployee.role
  }

  const [employeeOptions, setEmployeeOptions] = useState([])
  const [id, setEmployeeId] = useState(defaultEmployeeId)
  const [name, setEmployeeName] = useState(defaultEmployeeName)
  const [role, setEmployeeRole] = useState(defaultEmployeeRole)
  const [isActive, setIsActive] = useState(defaultIsActive)

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_ADMIN_BASE_URL}/v1/employees`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // const employeeOptions = data.data.map((item: any) => ({
  //       //   value: item.employee,
  //       //   label: item.employee
  //       // // }));
  //       // setEmployeeOptions(employeeOptions);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  return (
    <StyledModal
      modalWidth={500}
      modalHeight={500}
      loaderState={props.isLoading}
      isModalOpen={props.isModalOpen}
      modalTitle="Add employee"
      modalCloseHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledTextFieldWithLabel
          label="Employee id"
          placeholderText="Enter employee id"
          textValue={id}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmployeeId(e.target.value)
          }
          isDisabled={false}
          type="text"
          isMargin={true}
        />
        <StyledTextFieldWithLabel
          label="Employee name"
          placeholderText="Enter employee name"
          textValue={name}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmployeeName(e.target.value)
          }
          isDisabled={false}
          type="text"
          isMargin={true}
        />

        <StyledSelectField
          label="Designation"
          placeHolderText="Select designation"
          selectedValue={role}
          selectOptions={ROLES}
          onChangeHandler={(value: OptionItem) => setEmployeeRole(value)}
          isSearchable={true}
          isMulti={false}
          isDisabled={false}
          noOptionsMessage="No designation found"
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ color: '#000' }}>
            Add to employee active list
          </Typography>
          <Checkbox
            sx={{ padding: 0 }}
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
        </Box>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ color: '#fff' }}
          onClick={() =>
            props.isEditMode && props.selectedEmployee
              ? props.editEmployee &&
                props.editEmployee(props.selectedEmployee.id, {
                  id: props.selectedEmployee.id,
                  name: name,
                  role: role.value,
                  isActive
                })
              : props.addEmployee &&
                props.addEmployee({
                  id: id,
                  name: name,
                  role: role.value,
                  isActive: true
                })
          }
        >
          {props.isEditMode ? 'Update' : 'Add employee'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default EmployeeUploadModal
