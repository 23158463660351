import React from 'react'
import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

//styles and assets
import Machine from '../../../assets/machine-1.png'
import { getDisplayName } from '../../../utils/common.utils'
import { MACHINES } from '../../../utils/constant.utils'

const Detail = ({ label, value }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: '16px',
        fontWeight: 500
      }}
    >
      <p>{label}</p>
      <div
        style={{
          fontWeight: 700
        }}
      >
        {value}
      </div>
    </div>
  )
}

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '32px'
}))

const Card = styled('div')(({ theme, isSelected }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  padding: '16px',
  overflow: 'hidden',
  backgroundColor: isSelected ? '#ccccd7' : '#fff',
  color: '#000',
  borderRadius: '8px',
  cursor: 'pointer',
  boxShadow: '0 8px 28px 0 rgb(18 18 19 / 10%)',
  textAlign: 'center'
}))

const Card1 = styled('div')(({ isActive }) => ({
  width: '40%',
  padding: '4px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  borderRadius: '12px',
  backgroundColor: !isActive ? 'red' : 'transparent',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '50px',
    height: '600%',
    backgroundImage: isActive
      ? 'conic-gradient(lightgreen, lightgreen)'
      : 'conic-gradient(transparent, transparent)',
    animation: 'breatheAnimation 4s linear infinite'
  },
  '@keyframes breatheAnimation': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(350deg)'
    }
  }
}))

export const MachineImage = styled('img')(() => ({
  maxWidth: '100%'
}))

const LiveDataGrid = ({ machineData }) => {
  //hooks
  const navigate = useNavigate()

  return (
    <CardContainer>
      {machineData.length > 0 &&
        React.Children.toArray(
          machineData.map((machine) => (
            <Card1
              isActive={
                machine.machineStatus === true || machine.machineStatus === 1
                  ? false
                  : true
              }
            >
              <Card
                onClick={() =>
                  navigate(
                    `/dashboard/machine-wise?tab=overview&machine=${machine.machine}`
                  )
                }
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <p style={{ alignSelf: 'flex-start' }}>
                  <strong>{getDisplayName(machine.machine, MACHINES)}</strong>
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '4px'
                    }}
                  >
                    <ManageAccountsIcon fontSize="small" />
                    <p>{machine.operator}</p>
                  </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '30%' }}>
                    <MachineImage src={Machine} alt="company logo" />
                  </div>
                  <div
                    style={{
                      width: '70%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      rowGap: '4px'
                    }}
                  >
                    <Detail label="Part" value={machine.name} />
                    <Detail label="Operation" value={machine.type} />
                    <Detail label="Count" value={machine.partCount} />
                    <Detail label="Rejection" value={machine.rejectionCount} />
                    <Detail label="Idletime" value={machine.idleTime} />
                  </div>
                </div>
              </Card>
            </Card1>
          ))
        )}
    </CardContainer>
  )
}

export default LiveDataGrid
